import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "reakit/Button";
import Vimeo from "@u-wave/react-vimeo";
import YouTube from "@u-wave/react-youtube";
import { useResizer } from "../../customHooks";
import "./Video.scss";
import { Pause, PlayArrow } from "@material-ui/icons";
import { useIntl } from "react-intl";

const Video = ({ url, fileLink, active = true }) => {
  const [paused, setPaused] = useState(true);
  const [touched, setTouched] = useState(false);
  const [youTubePlayer, setYouTubePlayer] = useState();
  const isMobile = useResizer();
  const { formatMessage } = useIntl();

  const isVimeo = !fileLink && url.includes("vimeo");
  const isYoutube = !fileLink && url.includes("youtube");
  const youTubeId = isYoutube ? url.slice(-11) : undefined;

  const onPlayerReady = (event) => {
    setYouTubePlayer(event.target);
  };

  const clickHandler = () => {
    if (youTubePlayer) {
      if (paused) {
        youTubePlayer.playVideo();
      } else {
        youTubePlayer.pauseVideo();
      }
    }
    setPaused(!paused);
  };

  useEffect(() => {
    if (!active) {
      setPaused(true);
    }
  }, [active]);

  return (
    <div className={`VideoPlayer ${isVimeo ? "vimeo" : "other"}`}>
      {url && !fileLink && (
        <>
          <div
            className="buttonContainer"
            onTouchStart={() => setTouched(true)}
            onTouchEnd={() =>
              setTimeout(() => {
                setTouched(false);
              }, 3000)
            }
          >
            <Button
              as="div"
              aria-label={formatMessage({
                id: `aria.${paused ? "play" : "pause"}`,
                defaultMessage: "play/pause",
              })}
              className={`playPause ${
                paused || (isMobile && touched) ? "show" : "hide"
              } mobile-${isMobile}`}
              onClick={clickHandler}
            >
              {paused ? <PlayArrow /> : <Pause />}
            </Button>
          </div>
          {isVimeo ? (
            <Vimeo
              video={url}
              responsive={true}
              controls={false}
              paused={!active || paused}
            />
          ) : (
            <YouTube
              controls={false}
              video={youTubeId}
              paused={paused}
              width="100%"
              height="100%"
              onReady={onPlayerReady}
            />
          )}
        </>
      )}
      {fileLink && (
        <>
          {/* eslint-disable-next-line */}
          <video
            controls
            controlsList="nodownload"
            preload="auto"
            src={`${fileLink}#t=0.001`}
            type="video/mp4"
          />
        </>
      )}
    </div>
  );
};

Video.propTypes = {
  url: PropTypes.string,
  active: PropTypes.bool,
};

export default Video;
