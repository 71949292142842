import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import "./Logos.scss";
import { useIntl } from "react-intl";

const Logos = ({ logos }) => {
  const { locale } = useIntl();

  return logos ? (
    <ul className="logos">
      {logos.map((logo) => (
        <li key={logo.mediaItemUrl}>
          {logo.mediaTexts.mediaLink && logo.mediaTexts.mediaLink.length > 0 ? (
            <a
              href={logo.mediaTexts.mediaLink}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={logo.mediaItemUrl}
                alt={
                  locale === "en" && logo.mediaTexts.altTextEn
                    ? logo.mediaTexts.altTextEn
                    : logo.altText
                }
              />
            </a>
          ) : (
            <img
              src={logo.mediaItemUrl}
              alt={
                locale === "en" && logo.mediaTexts.altTextEn
                  ? logo.mediaTexts.altTextEn
                  : logo.altText
              }
            />
          )}
        </li>
      ))}
    </ul>
  ) : null;
};

Logos.propTypes = {
  logos: PropTypes.object,
};

export default Logos;
