import React from "react";
import { FormattedMessage } from "react-intl";
import "./NotFound.scss";

const NotFound = () => {
  return (
    <div className="NotFound">
      <FormattedMessage id="general.404" defaultMessage="404" />
    </div>
  );
};

export default NotFound;
