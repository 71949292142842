import React, { useEffect, useContext, useState, useCallback } from "react";
import {
  CarouselProvider,
  CarouselContext,
  Slider,
  Slide,
  DotGroup,
} from "pure-react-carousel";
import { useIntl } from "react-intl";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./Gallery.scss";
import Video from "../video/Video";
import { useResizer } from "../../customHooks";
import { activeMediaUrl } from "../../cache";
import { getUsedVideos } from "../../utilities";

function PhotoSlidesContext(props) {
  const carouselContext = useContext(CarouselContext);
  useEffect(() => {
    function onChange() {
      props.onSlideChange(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext, props]);
  return <></>;
}

const Gallery = ({ data, videos }) => {
  const { locale } = useIntl();
  const isMobile = useResizer();

  const [activeSlide, setActiveSlide] = useState(0);
  const [numImgs, setNumImgs] = useState(0);
  const [numVideos, setNumVideos] = useState(0);
  const [videoData, setVideoData] = useState([]);
  const imageData =
    isMobile && data.galleryMobile && data.galleryMobile.length
      ? data.galleryMobile
      : data.gallery;

  const pics = imageData || [];

  const allData =
    data.vidPosition === "start"
      ? [...videoData, ...pics]
      : [...pics, ...videoData];

  if (allData.length) {
    activeMediaUrl(allData[0].mediaItemUrl || allData[0].videoUrl);
  }

  useEffect(() => {
    if (allData && allData[activeSlide])
      activeMediaUrl(
        allData[activeSlide].mediaItemUrl || allData[activeSlide].videoUrl
      );
  }, [activeSlide]);

  useEffect(() => {
    if (imageData) {
      setNumImgs(imageData.length);
    }

    if (videos) {
      let vids = getUsedVideos(videos);
      setVideoData(vids);
      setNumVideos(vids.length);
    }
  }, [data, videos, imageData]);

  return allData.length > 0 ? (
    <div className="Gallery">
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={isMobile ? 100 : 50}
        totalSlides={numImgs + numVideos}
      >
        <Slider>
          {data.vidPosition === "start" &&
            videoData.length > 0 &&
            videoData.map((video, index) => (
              <Slide index={index} key={`video${index}`}>
                <div className="galleryImgContainer">
                  <Video
                    url={video.videoUrl}
                    fileLink={video.videoFile}
                    active={activeSlide === index}
                  />
                </div>
              </Slide>
            ))}
          {imageData &&
            imageData.map((img, index) => (
              <Slide
                index={
                  data.videoPosition === "start" ? numVideos + index : index
                }
                key={`img${index}`}
              >
                <div className="galleryImgContainer">
                  <img
                    src={img.mediaItemUrl}
                    alt={
                      locale === "en" && img.mediaTexts.altTextEn
                        ? img.mediaTexts.altTextEn
                        : img.altText
                    }
                  />
                </div>
              </Slide>
            ))}
          {data.vidPosition === "end" &&
            videoData.length > 0 &&
            videoData.map((video, index) => (
              <div className="galleryImgContainer" key={video.videoUrl}>
                <Slide index={numImgs + index}>
                  <div className="galleryImgContainer">
                    <Video
                      url={video.videoUrl}
                      fileLink={video.videoFile}
                      active={activeSlide === numImgs + index}
                    />
                  </div>
                </Slide>
              </div>
            ))}
          <PhotoSlidesContext
            onSlideChange={(index) => setActiveSlide(index)}
          />
        </Slider>
        {numImgs + numVideos > 1 && <DotGroup className="galleryDots" />}
      </CarouselProvider>
    </div>
  ) : null;
};

export default Gallery;
