import React, { useState, Fragment, useEffect } from "react";
import { useApolloClient, useQuery } from "@apollo/client";
import { NavLink, useRouteMatch, Route, useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import parse from "html-react-parser";
import "./People.scss";
import Person from "../person/Person";
import { useResizer } from "../../customHooks";
import { GET_PERSON, GET_SHOWS_BY_PERSON, GET_PEOPLE } from "../../queries";
import Loader from "../atoms/loader/Loader";
import { HashLink } from "react-router-hash-link";

export const deadPeople = ["René Pollesch"];

// mapping jobtypes to corresponding custom fields
export const jobTypes = {
  author: "productionAuthors",
  actor: "productionActors",
  "dance-choreo": "productionDanceChoreo",
  "performance-choreo": "productionPerformanceChoreo",
  "dance-choir": "productionDanceChoir",
  choir: "productionChoir",
  director: "productionDirectors",
  "text-director": "productionTextDirector",
  "concept-director": "productionConceptDirector",
  "set-design": "productionSetDesign",
  costumes: "productionCostumes",
  "set-costumes": "productionSetCostumes",
  choreography: "productionChoreography",
  "real-choreo": "productionRealChoreo",
  acrobat: "productionAcrobat",
  "composition-sound": "productionCompositionSound",
  music: "productionMusic",
  "live-music": "productionLiveMusic",
  video: "productionVideo",
  "video-concept": "productionVideoConcept",
  "video-design": "productionVideoDesign",
  "live-camera": "productionLiveCamera",
  lighting: "productionLighting",
  "light-design": "productionLightDesign",
  dramaturgy: "productionDramaturgy",
  "dramaturgy-rt": "productionDramaturgyRt",
  // "music-coach": "productionMusicCoach",
};

const jobCategories = [
  "acting",
  "choir",
  "p14",
  "directing",
  "stage",
  "costumes",
  "music",
  "soufflage",
  "inspecting",
  "dramaturgy",
  "cinema",
  "sound-video",
  "lighting",
  "requisite",
  "makeup",
  "werkstatt",
  "stagetech",
  "tech",
  "house",
  "service",
  "tickets",
  "press",
  "kbb",
  "kbb-lead",
  "staffadmin",
  "sales",
  "management",
  "leading-director",
];

const People = () => {
  const { path, url } = useRouteMatch();
  let location = useLocation();
  const { locale, formatMessage } = useIntl();
  const [peopleData, setPeopleData] = useState();
  const [loading, setLoading] = useState(true);
  const isMobile = useResizer();
  const client = useApolloClient();

  const fetchAll = async () => {
    let hasNextPage = true;
    let cursor = null;
    let allPeople = [];

    while (hasNextPage) {
      const { data } = await client.query({
        query: GET_PEOPLE,
        variables: { after: cursor },
      });

      allPeople = [...allPeople, ...data.persons.nodes];
      hasNextPage = data.persons.pageInfo.hasNextPage;
      cursor = data.persons.pageInfo.endCursor;
    }
    setLoading(false);
    sortPeopleByFunction(allPeople);
  };

  useEffect(() => {
    fetchAll();
  }, []);

  // const { data, loading, error, client, fetchMore } = useQuery(GET_PEOPLE, {
  //   onCompleted: (data) => {
  //     fetchAllPeople(data.persons.pageInfo);
  //     sortPeopleByFunction(data.persons.nodes);
  //   },
  // });

  const sortPeopleByFunction = (allPeople) => {
    const peopleByFunction = jobCategories.map((cat) => {
      let people = allPeople.filter((x) =>
        x.personDetails.jobCategory.includes(cat)
      );

      let newPeople = people.map((person) => {
        if (
          ["van der", "van den", "van ", "von "].some((prefix) => {
            return person.person_import.lastName.startsWith(prefix);
          })
        ) {
          let splitAt = person.person_import.lastName.lastIndexOf(" ");

          let newPersonImport = {
            firstName: `${
              person.person_import.firstName
            } ${person.person_import.lastName.substr(0, splitAt)}`.trim(),
            lastName: person.person_import.lastName.substr(splitAt).trim(),
            personId: person.person_import.personId,
          };

          let newPerson = {
            ...person,
          };

          newPerson.person_import = newPersonImport;
          return newPerson;
        }
        return person;
      });

      newPeople.sort((a, b) => {
        if (
          a.person_import.lastName &&
          b.person_import.lastName &&
          a.person_import.lastName !== b.person_import.lastName
        ) {
          return a.person_import.lastName.localeCompare(
            b.person_import.lastName
          );
        }

        return a.person_import.firstName.localeCompare(
          b.person_import.firstName
        );
        // return a.person_import.lastName < b.person_import.lastName
        //   ? -1
        //   : a.person_import.lastName > b.person_import.lastName
        //   ? 1
        //   : 0;
      });

      return {
        jobtype: cat,
        people: newPeople,
      };
    });

    setPeopleData(peopleByFunction);
  };

  const preloadData = (person) => {
    client.query({
      query: GET_PERSON,
      variables: { id: person.slug },
    });
    client.query({
      query: GET_SHOWS_BY_PERSON,
      variables: { id: String(person.person_import.personId) },
    });
  };

  const sortActors = (actors) => {
    let letterMap = [];

    for (let actor of actors) {
      const lastName = actor.person_import.lastName;
      const firstName = actor.person_import.lastName;
      const letter = lastName ? lastName.charAt(0) : firstName.charAt(0);
      let obj = letterMap.find((x) => x.letter === letter);

      if (obj) {
        obj.actors.push(actor);
      } else {
        obj = {
          letter: letter.toUpperCase(),
          actors: [actor],
        };
        letterMap.push(obj);
      }
    }

    for (let obj of letterMap) {
      obj.actors.sort((a, b) => {
        if (
          a.person_import.lastName &&
          b.person_import.lastName &&
          a.person_import.lastName !== b.person_import.lastName
        ) {
          return a.person_import.lastName.localeCompare(
            b.person_import.lastName
          );
        }

        return a.person_import.firstName.localeCompare(
          b.person_import.firstName
        );
      });
    }

    letterMap.sort((a, b) => {
      return a.letter < b.letter ? -1 : a.letter > b.letter ? 1 : 0;
    });

    return letterMap;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="People">
      <nav className="screenreader">
        {peopleData &&
          peopleData.map(
            (item) =>
              item.people.length > 0 && (
                <HashLink key={item.jobtype} to={`#${item.jobtype}`}>
                  {parse(
                    formatMessage({
                      id: `jobtype.${item.jobtype}`,
                      values: { count: item.people.length },
                    })
                  )}
                </HashLink>
              )
          )}
      </nav>
      {isMobile && (
        <Route exact path={`${path}/:person`}>
          <Person key={location.pathname} backLink={path} />
        </Route>
      )}
      {/* {(!isPersonLoaded || !isMobile) && ( */}
      <div className="list">
        {peopleData &&
          peopleData.map(
            (item) =>
              item.people.length > 0 && (
                <Fragment key={item.jobtype}>
                  <h2 id={item.jobtype}>
                    {parse(
                      formatMessage({
                        id: `jobtype.${item.jobtype}`,
                        values: { count: item.people.length },
                      })
                    )}
                  </h2>
                  <nav>
                    {peopleData &&
                      (item.jobtype === "acting"
                        ? sortActors(item.people).map((letter) => (
                            <div className="actorsList">
                              <span className="startLetter">
                                &gt;&nbsp;{letter.letter}&nbsp;
                              </span>
                              {letter.actors.map((person) => (
                                <NavLink
                                  to={`${path}/${person.slug}`}
                                  className="personLink"
                                  activeClassName="active"
                                  onMouseOver={() => preloadData(person)}
                                >
                                  <span className="name">{person.title}</span>
                                  <span className="comma">, </span>
                                </NavLink>
                              ))}
                            </div>
                          ))
                        : item.people.map((person) => (
                            <NavLink
                              to={`${path}/${person.slug}`}
                              className="personLink"
                              activeClassName="active"
                              onMouseOver={() => preloadData(person)}
                            >
                              <span className="name">{person.title}</span>
                              <span className="comma">, </span>
                            </NavLink>
                          )))}
                  </nav>
                </Fragment>
              )
          )}
        {/* {data &&
          data.pages &&
          data.pages.nodes &&
          data.pages.nodes[0] &&
          data.pages.nodes[0].text_content &&
          data.pages.nodes[0].text_content.contentDe && (
            <div>
              {parse(
                locale === "de"
                  ? data.pages.nodes[0].text_content.contentDe
                  : data.pages.nodes[0].text_content.contentEn ||
                      "English Version coming soon"
              )}
            </div>
          )} */}
      </div>
      {!isMobile && (
        <Route exact path={`${path}/:person`}>
          <Person />
        </Route>
      )}
    </div>
  );
};

export default People;
