import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Link,
  NavLink,
  Redirect,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import parse from "html-react-parser";
import "./CategoryOverview.scss";
import PersonName from "../atoms/PersonName";
import {
  customInfoMapping,
  filterActiveShows,
  mapPerson,
  nowFormatted,
  showWillPremiere,
  sortShowsByDate,
  sortShowsByDateReverse,
  productionIsInSeason,
} from "../../utilities";
import {
  ignoreSpecial,
  specialProductionRoutes,
  venues,
} from "../../i18n/route-utils";
import Loader from "../atoms/loader/Loader";
import {
  GET_ARCHIVED_SHOWS,
  GET_CATEGORY_OVERVIEW_SETTINGS,
  GET_SERIES,
  GET_SHOWS,
  GET_SPIELZEITEN,
} from "../../queries";
import moment from "moment";

const CategoryOverview = ({ selected, archive, withPress }) => {
  const [links, setLinks] = useState();
  const [filteredLinks, setFilteredLinks] = useState();
  const [mostRecentSeason, setMostRecentSeason] = useState();
  const { url, path } = useRouteMatch();
  const { year } = useParams();
  const { locale, formatMessage } = useIntl();

  const { data: settings, error: settingsError } = useQuery(
    GET_CATEGORY_OVERVIEW_SETTINGS
  );

  const { data: spielzeiten } = useQuery(GET_SPIELZEITEN, {
    onCompleted: (seasons) => {
      let highest = "";
      for (let season of seasons.spielzeiten.nodes) {
        highest = season.slug > highest ? season : highest;
      }

      if (highest) setMostRecentSeason(highest.slug);
    },
    skip: !archive,
  });

  const processLoadedData = (data) => {
    const linkArray = filterActiveShows(data.shows.nodes);
    let result = filterResults(linkArray);

    setLinks(result);
    setFilteredLinks(result);
  };

  const { loading } = useQuery(GET_SHOWS, {
    onCompleted: (data) => processLoadedData(data),
    skip: selected === "series" || archive,
  });

  const { loading: seriesLoading } = useQuery(GET_SERIES, {
    onCompleted: (data) => processLoadedData(data),
    skip: selected !== "series" || archive,
  });

  const { loading: archiveLoading } = useQuery(GET_ARCHIVED_SHOWS, {
    onCompleted: (data) => processLoadedData(data),
    skip: !archive,
  });

  useEffect(() => {
    if (year && !["series", "reihen"].includes(year) && spielzeiten && links) {
      console.log("normal year");
      const spielzeit = spielzeiten.spielzeiten.nodes.find(
        (x) => x.slug === year
      );

      const start = moment(spielzeit.spielzeitInfo.spielzeitStart);
      const end = moment(spielzeit.spielzeitInfo.spielzeitEnd);

      let newLinks = links.filter(
        (y) =>
          y.productionInfo.premiereEvent &&
          moment(
            y.productionInfo.premiereEvent.eventDetails.startTime
          ).isSameOrAfter(start) &&
          moment(
            y.productionInfo.premiereEvent.eventDetails.startTime
          ).isSameOrBefore(end)
      );

      setFilteredLinks(newLinks);
    } else if (year && ["series", "reihen"].includes(year) && links) {
      let newLinks = links.filter((y) => y.productionDetails.isSeries);
      setFilteredLinks(newLinks);
    } else if (links) {
      setFilteredLinks(links);
    }
  }, [year, spielzeiten, links]);

  const filterResults = (data) => {
    let result;

    if (!archive && !withPress) {
      result = data.filter((x) => x.productionInfo.showInRepertoire === true);
      result =
        selected === "series"
          ? result.filter((x) => x.productionDetails.isSeries === true)
          : result.filter((x) => x.productionDetails.isSeries !== true);
    }

    if (archive && !withPress) {
      result = data.filter((x) => x.productionInfo.showInArchive === true);
    }

    if (withPress) {
      result = data.filter(
        (x) => x.pressFiles.pressPhotos && x.pressFiles.pressPhotos.length > 0
      );
    }

    if (selected === "premieres") {
      result = result.filter((x) =>
        productionIsInSeason(
          x,
          settings.vbSetting.vb_custom_settings.currentSeason
        )
      );
    }

    if (selected === "repertoire") {
      result = result.filter((x) => !showWillPremiere(x));
    }

    if (selected === "series") {
      result = orderSeries(result);
    } else {
      result.sort(
        archive || selected === "premieres"
          ? sortShowsByDate
          : sortShowsByDateReverse
      );
    }

    return result;
  };

  const orderSeries = (data) => {
    if (
      data &&
      selected === "series" &&
      settings &&
      !settingsError &&
      settings.vbSetting.vb_custom_settings.seriesOrder &&
      settings.vbSetting.vb_custom_settings.seriesOrder.length
    ) {
      let restLinks = [...data];
      let orderedSeries = [];
      let orderedTitles = settings.vbSetting.vb_custom_settings.seriesOrder.map(
        (entry) => entry.title
      );
      let titleSet = new Set(orderedTitles);

      for (let t of titleSet) {
        if (t) {
          let found = data.find(({ title }) => title === t);
          if (found) {
            restLinks.splice(restLinks.indexOf(found), 1);
            orderedSeries.push(found);
          }
        }
      }

      let newLinks = [...orderedSeries, ...restLinks];
      return newLinks;
    } else {
      return data;
    }
  };

  const LinkContent = ({ link }) => (
    <div>
      <h3>
        {link.title}
        {link.productionDetails.titleAddition && (
          <span className="subtitle">
            &nbsp;&ndash;&nbsp;{link.productionDetails.titleAddition}
          </span>
        )}
      </h3>
      <p>
        {link.productionDetails.subtitle && (
          <span className="detail">{link.productionDetails.subtitle}</span>
        )}
        {link.productionCast.productionAuthors && (
          <span className="detail">
            <span role="separator" className="separator">
              <span aria-hidden>&nbsp;&bull;&nbsp;</span>
            </span>
            <FormattedMessage id="show.by" defaultMessage="von" />
            &nbsp;{link.productionCast.productionAuthors}
            {/* {mapPerson(link.productionCast.productionAuthors.split(",")).map(
              (person) => (
                <PersonName id={person.id} name={person.name} key={person.id} />
              )
            )} */}
          </span>
        )}
        {link.productionDetails.originalTextDe && (
          <span className="detail">
            {!link.productionCast.productionAuthors && (
              <span role="separator" className="separator">
                <span aria-hidden>&nbsp;&bull;&nbsp;</span>
              </span>
            )}
            &nbsp;
            {locale === "en" && link.productionDetails.originalTextEn
              ? link.productionDetails.originalTextEn
              : link.productionDetails.originalTextDe}
          </span>
        )}
        {link.productionCast.productionDirectors &&
          link.productionCast.productionDirectors.length > 1 && (
            <span className="detail">
              <span role="separator" className="separator">
                <span aria-hidden>&nbsp;&bull;&nbsp;</span>
              </span>
              <FormattedMessage
                id="jobtype.director"
                values={{
                  count: link.productionCast.productionDirectors
                    .trim()
                    .split(",").length,
                }}
                defaultMessage="Regie"
              />
              &#58;&nbsp;
              {mapPerson(
                link.productionCast.productionDirectors.split(",")
              ).map((person, index) => (
                <React.Fragment key={person.id}>
                  <PersonName id={person.id} name={person.name} />
                  {index + 1 <
                    link.productionCast.productionDirectors.split(",").length -
                      1 && <span>, </span>}
                </React.Fragment>
              ))}
            </span>
          )}
        {link.productionInfo.premiereEvent && (
          <span className="detail">
            <span role="separator" className="separator">
              <span aria-hidden>&nbsp;&bull;&nbsp;</span>
            </span>
            {}
            <FormattedMessage
              id={`special.${
                !ignoreSpecial.includes(
                  link.productionInfo.premiereEvent.eventDetails.customInfo
                ) &&
                customInfoMapping[
                  parse(
                    link.productionInfo.premiereEvent.eventDetails.customInfo
                  )
                ]
                  ? customInfoMapping[
                      parse(
                        link.productionInfo.premiereEvent.eventDetails
                          .customInfo
                      )
                    ]
                  : "premiere"
              }`}
              defaultMessage="Premiere"
            />
            &#58;&nbsp;
            {moment(
              link.productionInfo.premiereEvent.eventDetails.startTime
            ).format("DD.MM.YYYY")}
          </span>
        )}
        {!link.productionInfo.premiereEvent &&
          link.productionInfo.plannedPremiere.premiereDate && (
            <span className="detail">
              <span role="separator" className="separator">
                <span aria-hidden>&nbsp;&bull;&nbsp;</span>
              </span>
              {}
              <FormattedMessage
                id={`special.${link.productionInfo.plannedPremiere.premiereType}`}
                defaultMessage="Premiere"
              />
              <FormattedMessage id="general.inMonth" defaultMessage=" im " />
              {moment(link.productionInfo.plannedPremiere.premiereDate)
                .locale(locale)
                .format("MMMM YYYY")}
            </span>
          )}
        {link.productionInfo.venue && selected !== "series" && (
          <span className="detail">
            <span role="separator" className="separator">
              <span aria-hidden>&nbsp;&bull;&nbsp;</span>
            </span>
            <FormattedMessage
              id={`venue.${venues[link.productionInfo.venue]}`}
              defaultMessage={link.productionInfo.venue}
            />
          </span>
        )}
      </p>
    </div>
  );

  const getBaseUrl = (slug) => {
    if (!withPress) {
      for (let route of specialProductionRoutes) {
        if (slug.startsWith(route)) {
          let baseRoute = formatMessage({
            id: `route.${route.replace(/\/$/, "")}`,
          });

          return `/${locale}/${baseRoute}`;
        }
      }
    }

    let newRoute =
      archive || selected === "premieres"
        ? `/${locale}/${formatMessage({
            id: "route.repertoire",
          })}`
        : url.replace(/\/$/, "");

    if (archive && ["series", "reihen"].includes(year)) {
      newRoute = `/${locale}/${formatMessage({
        id: "route.series",
      })}`;
    }

    return newRoute;
  };

  if (loading || seriesLoading || archiveLoading) {
    return <Loader />;
  }

  if (archive && !year && mostRecentSeason) {
    return <Redirect to={`${path}/${mostRecentSeason}`} />;
  }

  return (
    <div>
      {archive && spielzeiten && (
        <nav
          className="pageTabs spielzeiten"
          role="navigation"
          aria-label={formatMessage({
            id: "aria.spielzeiten",
            defaultMessage: "Spielzeiten",
          })}
        >
          <span>
            {formatMessage({
              id: "general.spielzeit",
              defaultMessage: "Spielzeit",
            })}
            :&nbsp;
          </span>
          {spielzeiten.spielzeiten.nodes.map((spielzeit) => (
            <NavLink
              key={spielzeit.title}
              to={`/${locale}/${formatMessage({
                id: "route.archive",
              })}/${spielzeit.slug}`}
              activeClassName="selected"
            >
              <span className="title">{spielzeit.title}</span>
              <span role="separator" className="separator">
                {" "}
                &bull;&nbsp;
              </span>
            </NavLink>
          ))}
          {archive && (
            <NavLink
              to={`/${locale}/${formatMessage({
                id: "route.archive",
              })}/${formatMessage({
                id: "route.series",
              })}`}
              activeClassName="selected"
            >
              <span className="title">
                <FormattedMessage id="menu.series" />
              </span>
              <span role="separator" className="separator">
                {" "}
                &bull;&nbsp;
              </span>
            </NavLink>
          )}
        </nav>
      )}
      {/* {!archive && (
        <nav
          className="pageTabs showType"
          role="navigation"
          aria-label={formatMessage({
            id: "aria.shows-series",
            defaultMessage: "Produktionen / Reihen",
          })}
        >
          <NavLink
            exact
            to={`/${locale}/${formatMessage({
              id: "route.repertoire",
            })}`}
            activeClassName="selected"
          >
            <span className="title">
              <FormattedMessage
                id="repertoire.repertoire"
                defaultMessage="Repertoire"
              />
            </span>
          </NavLink>
          <span className="separator"> &bull;&nbsp;</span>
          <NavLink
            to={`/${locale}/${formatMessage({
              id: "route.repertoire",
            })}/${formatMessage({
              id: "route.series",
            })}`}
            activeClassName="selected"
          >
            <span className="title">
              <FormattedMessage
                id="repertoire.series"
                defaultMessage="Reihen/Serien"
              />
            </span>
          </NavLink>
        </nav>
      )} */}

      <nav aria-label="Links" className="linkGrid">
        {links && (
          <>
            {settings && withPress && (
              <>
                {settings.vbSetting.vb_custom_settings.presstext && (
                  <div className="presstext">
                    {parse(
                      locale === "en" &&
                        settings.vbSetting.vb_custom_settings.presstextEn
                        ? settings.vbSetting.vb_custom_settings.presstextEn
                        : settings.vbSetting.vb_custom_settings.presstext
                    )}
                  </div>
                )}
                <Link className="productionLink" to={`${url}/volksbuehne`}>
                  <div>
                    <h3>
                      <FormattedMessage
                        id="press.volksbuehne"
                        defaultMessage="Pressematerial Volksbühne"
                      />
                    </h3>
                  </div>
                </Link>
              </>
            )}
            {filteredLinks &&
              filteredLinks.map((link) => (
                <Link
                  to={`${getBaseUrl(link.slug)}/${link.slug}`}
                  key={link.slug}
                  className="productionLink"
                >
                  <LinkContent link={link} key={link.slug} />
                </Link>
              ))}
            {archive && year.includes("23") && (
              <Link to={`/${locale}/prater-studios`} className="productionLink">
                <div>
                  <h3>
                    <FormattedMessage
                      id="venue.prater-studios"
                      defaultMessage="Prater Studios"
                    />
                  </h3>
                </div>
              </Link>
            )}
          </>
        )}
      </nav>
    </div>
  );
};

export default CategoryOverview;
