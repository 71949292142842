import React from "react";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";

const SeoTags = ({ title, titleEn, seo, intTitleId }) => {
  const { locale, formatMessage } = useIntl();

  return (
    <Helmet>
      {intTitleId ? (
        <title>{parse(formatMessage({ id: `${intTitleId}` }))}</title>
      ) : (
        <title>{locale === "en" && titleEn ? titleEn : title}</title>
      )}
      {seo && seo.seoDescription && (
        <meta
          name="description"
          content={
            locale === "en" && seo.seoDescriptionEn
              ? seo.seoDescriptionEn
              : seo.seoDescription
          }
        />
      )}
      {seo && seo.seoKeywords && (
        <meta
          name="keywords"
          content={
            locale === "en" && seo.seoKeywordsEn
              ? seo.seoKeywordsEn
              : seo.seoKeywords
          }
        />
      )}
    </Helmet>
  );
};

export default SeoTags;
