import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SearchResult from "./searchResult/SearchResult";
import { gql, useQuery } from "@apollo/client";
import "./Search.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { filterActiveShows } from "../../utilities";
import { useOutsideAlerter } from "../../customHooks";
import { SEARCH_POSTS } from "../../queries";

const Search = ({ onClose, mobile, openedMenu, toggleOpen }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [searchOpen, setSearchOpen] = useState(false);
  const { formatMessage } = useIntl();
  const searchRef = useRef();

  useOutsideAlerter(
    searchRef,
    mobile
      ? () => {}
      : () => {
          onClose();
          setSearchOpen(false);
          setSearchTerm("");
        }
  );

  useEffect(() => {
    if (openedMenu === "months") setSearchOpen(false);
  }, [openedMenu]);

  useEffect(() => {
    if (toggleOpen && searchOpen) toggleOpen("search");
  }, [toggleOpen, searchOpen]);

  const { loading } = useQuery(SEARCH_POSTS, {
    variables: { search: searchTerm },
    onCompleted: (data) => {
      const resultData = {};
      if (data.persons.nodes.length > 0) {
        resultData.persons = data.persons.nodes;
      }
      if (
        data.shows.nodes.length &&
        filterActiveShows(data.shows.nodes).length > 0
      ) {
        const filtered = filterActiveShows(data.shows.nodes);
        const arrUniq = [...new Map(filtered.map((v) => [v.id, v])).values()];
        resultData.shows = arrUniq;
      }
      if (
        data.singleEvents.nodes.length &&
        filterActiveShows(data.singleEvents.nodes).length > 0
      ) {
        const filtered = filterActiveShows(data.singleEvents.nodes);
        const arrUniq = [...new Map(filtered.map((v) => [v.id, v])).values()];
        resultData.singleEvents = arrUniq;
      }
      if (data.pages.nodes.length > 0) {
        const arrUniq = [
          ...new Map(data.pages.nodes.map((v) => [v.id, v])).values(),
        ];
        resultData.pages = arrUniq;
      }

      setResults(resultData);
    },
    skip: !searchTerm || searchTerm.length < 1,
  });

  return (
    <div className="search" ref={searchRef} role="search">
      <input
        aria-label={formatMessage({ id: "menu.search" })}
        name="vb-search"
        type="text"
        value={searchTerm}
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
        // onKeyPress={(e) => {
        //   if (e.key === "Enter") {
        //     e.target.blur();
        //   }
        // }}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setSearchOpen(true);
        }}
        placeholder={formatMessage({ id: "menu.search" })}
      />
      {searchTerm.length > 0 && searchOpen && openedMenu !== "months" && (
        <div className="dropDown">
          <div className="results">
            {loading ? (
              <span className="loader">
                <FormattedMessage id="search.loading" defaultMessage="[...]" />
              </span>
            ) : results && Object.keys(results).length ? (
              Object.keys(results).map((type) =>
                results[type].map((result) => (
                  <SearchResult
                    key={`${type}-${result.id}`}
                    data={result}
                    type={type}
                    onClose={() => {
                      onClose();
                      setSearchOpen(false);
                      setSearchTerm("");
                    }}
                  />
                ))
              )
            ) : (
              <span className="loader">
                <FormattedMessage
                  id="search.noResults"
                  defaultMessage="[...]"
                />
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Search;
