import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { headline } from "../../cache";
import { Redirect } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import parse from "html-react-parser";
import {
  GET_AUDIO_EVENTS,
  GET_PAGE_BY_TITLE,
  GET_TIME_FRAME,
} from "../../queries";
import Loader from "../atoms/loader/Loader";
import de from "../../i18n/localizations/de.json";
import "./Page.scss";
import MediaCredits from "../atoms/mediaCredits/MediaCredits";
import Gallery from "../gallery/Gallery";
import EventCalendar from "../eventCalendar/EventCalendar";
import { todayDateString, zeroPad } from "../../utilities";
import SeoTags from "../atoms/seoTags/SeoTags";

const Page = ({ title, type, noTitle }) => {
  const { locale } = useIntl();
  const [pageData, setPageData] = useState();
  const searchTitle = de[`menu.${title}`];

  const { loading, error } = useQuery(GET_PAGE_BY_TITLE, {
    variables: { title: searchTitle },
    onCompleted: (data) => {
      const page = data.pages.nodes[0];
      setPageData(page);
    },
  });

  const { data: months } = useQuery(GET_TIME_FRAME);

  const { data: audioEvents, loading: audioLoading } = useQuery(
    GET_AUDIO_EVENTS,
    {
      variables: {
        start: todayDateString,
        end: `${months.timeFrame.end.year}${zeroPad(
          months.timeFrame.end.month,
          2
        )}31`,
      },
      onError: (error) => {
        console.log(error);
      },
      skip: title !== "audiodescription",
    }
  );

  if (type !== "largeTitle") {
    headline(title);
  } else {
    headline("");
  }

  if (error) {
    return <Redirect to={`/${locale}/404`} />;
  }

  return (
    <>
      {pageData && <SeoTags intTitleId={`menu.${title}`} seo={pageData.seo} />}
      {!loading && pageData ? (
        <div className="page">
          {type === "largeTitle" && (
            <>
              <div className="topImage">
                <Gallery
                  data={pageData.gallery}
                  videos={pageData.productionVideos}
                />
              </div>
              {!noTitle && (
                <div className="titleSection">
                  <h1>
                    {parse(
                      locale === "de"
                        ? pageData.titles.titleDe
                        : pageData.titles.titleDe
                    )}
                  </h1>
                </div>
              )}
            </>
          )}
          <section className="mainContent">
            {pageData.text_content && pageData.text_content.contentDe && (
              <main
                className={`textContent ${type === "largeTitle" && "nocols"}`}
              >
                {title === "audiodescription" && (
                  <>
                    <div className="calendarWrapper audio">
                      {audioLoading ? (
                        <Loader />
                      ) : (
                        <>
                          <h3>
                            <FormattedMessage
                              id="audiodescription.calendar-headline"
                              values={{
                                count: audioEvents.events.nodes.length,
                              }}
                              defaultMessage="Die nächste Vorstellung mit Audiodeskription ist:"
                            />
                          </h3>
                          <EventCalendar
                            key="evt-calendar-audio"
                            venueId={117}
                            type="noQuery"
                            data={audioEvents.events.nodes}
                            withSeparator
                          />
                        </>
                      )}
                    </div>
                  </>
                )}
                {parse(
                  locale === "de"
                    ? pageData.text_content.contentDe
                    : pageData.text_content.contentEn ||
                        "English Version coming soon"
                )}
              </main>
            )}

            {pageData.gallery && !noTitle && (
              <MediaCredits
                galleryImages={pageData.gallery.gallery}
                vidPosition={pageData.gallery.vidPosition}
                videoData={pageData.productionVideos}
              />
            )}
          </section>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

Page.propTypes = {
  title: PropTypes.string,
};

export default Page;
