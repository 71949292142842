import React from "react";
import { NavLink } from "react-router-dom";
import "./Footer.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { footerItems } from "../../i18n/route-utils";
import { useApolloClient } from "@apollo/client";
import { GET_PAGE_BY_TITLE } from "../../queries";

export const Footer = ({ hideAktuelles }) => {
  const { locale, formatMessage } = useIntl();
  const client = useApolloClient();

  const preloadData = (page) => {
    client.query({
      query: GET_PAGE_BY_TITLE,
      variables: { title: page },
    });
  };

  const useFooterItems = hideAktuelles
    ? footerItems.filter((x) => x !== "news")
    : footerItems;

  return (
    <footer>
      <nav>
        {useFooterItems.map((item) => (
          <NavLink
            key={item}
            to={`/${locale}/${formatMessage({ id: `route.${item}` })}`}
            activeClassName="active"
          >
            <span
              onMouseOver={() => preloadData(item)}
              onFocus={() => preloadData(item)}
            >
              <FormattedMessage id={`menu.${item}`} defaultMessage={item} />
            </span>
          </NavLink>
        ))}
      </nav>
    </footer>
  );
};

export default Footer;
