import React from "react";
import { useIntl } from "react-intl";
import "./Loader.scss";
import loading from "../../../assets/loading.gif";

const Loader = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="Loader" aria-busy="true">
      <img
        src={loading}
        alt={formatMessage({ id: "alt.loading", defaultMessage: "loading" })}
      />
    </div>
  );
};

export default Loader;
