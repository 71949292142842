import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { GET_PERSON_BY_ID } from "../../queries";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { getRouteKeys } from "../../i18n/route-utils";
import { deadPeople } from "../people/People";

const PersonName = ({ id, name, link }) => {
  const { locale } = useIntl();
  const { data } = useQuery(GET_PERSON_BY_ID, {
    variables: { id },
  });

  // if (deadPeople.includes(name)) {
  //   name = `${name} †`;
  // }

  return link &&
    data &&
    data.persons &&
    data.persons.nodes &&
    data.persons.nodes[0] ? (
    <Link
      to={`/${locale}/${getRouteKeys("en", "crew")[locale]}/${
        data.persons.nodes[0].slug
      }`}
    >
      <nobr>
        {name
          ? parse(name)
          : parse(
              `${data.persons.nodes[0].person_import.firstName} ${data.persons.nodes[0].person_import.lastName}`
            )}
      </nobr>
    </Link>
  ) : (
    <span>
      <nobr>
        {name
          ? parse(name)
          : data &&
            data.persons &&
            data.persons.nodes &&
            data.persons.nodes[0] &&
            parse(
              `${data.persons.nodes[0].person_import.firstName} ${data.persons.nodes[0].person_import.lastName}`
            )}
      </nobr>
    </span>
  );
};

PersonName.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  link: PropTypes.bool,
};

export default PersonName;
