import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, Redirect, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  eventIsInPast,
  mapPerson,
  replaceBacktick,
  replaceUmlaute,
  showActive,
  sortPeople,
} from "../../utilities";
import Gallery from "../gallery/Gallery";
import { getVenueClass, venues } from "../../i18n/route-utils";
import parse from "html-react-parser";
import PersonName from "../atoms/PersonName";
import EventCalendar from "../eventCalendar/EventCalendar";
import "./Show.scss";
import { animationActive, headline } from "../../cache";
import { jobTypes } from "../people/People";
import { GET_SHOW } from "../../queries";
import MediaCredits from "../atoms/mediaCredits/MediaCredits";
import Loader from "../atoms/loader/Loader";
import Logos from "../logos/Logos";
import SeoTags from "../atoms/seoTags/SeoTags";
import IntText from "../atoms/intTexts/IntTexts";
import { HashLink } from "react-router-hash-link";

const ignoreJobs = { 695: ["Kinderbetreuung"] };
const crewFirst = [804];

const getPeopleWithAlternatives = (people, role) => {
  if (people[0] && people[0].understudy_rank) {
    let person = people.shift();
    let alternatives = people.map((a) => {
      return {
        ...a,
        name: a.first_name + " " + a.last_name,
      };
    });
    if (person) {
      return [
        {
          ...person,
          name: person.first_name + " " + person.last_name,
          sort: role.role_sort,
          role_id: role.id,
          alternatives,
        },
      ];
    }
  } else {
    return people.map((person) => {
      return {
        ...person,
        name: person.first_name + " " + person.last_name,
        sort: role.role_sort,
        role_id: role.id,
      };
    });
  }

  return null;
};

const Show = ({ linkBack, linkBackKey, showArchive }) => {
  const { slug } = useParams();
  const { locale, formatMessage } = useIntl();
  const description = useRef();
  const titleRef = useRef();
  const [titleHeight, setTitleHeight] = useState();
  const [cast, setCast] = useState();
  const [jobMap, setJobMap] = useState();
  const [peopleData, setPeopleData] = useState();
  const [isActive, setIsActive] = useState();
  const multiLine = [
    "actor",
    "mit",
    "und",
    // "tanz",
    "von-und-mit",
    "choir",
    "dance-choreo",
    "performance-choreo",
    "performance-choreographie",
    "live-musik",
    "musik-von-und-mit",
    "musiker",
  ];

  const { data, loading, error } = useQuery(GET_SHOW, {
    variables: { slug },
    onCompleted: (data) => {
      setIsActive(showActive(data.show));
      const allPeople = {};

      for (let jobtype in jobTypes) {
        let people =
          data.show &&
          data.show.productionCast &&
          data.show.productionCast[jobTypes[jobtype]]
            ? data.show.productionCast[jobTypes[jobtype]].split(",")
            : [];

        allPeople[jobtype] = mapPerson(people);
      }

      setPeopleData(allPeople);
      sortCast(data.show);

      //Intro Animation for Fantomas
      // if (data.show.slug.includes("fantomas")) {
      //   setTimeout(() => {
      //     animationActive(true);
      //   }, 4000);
      // }
    },
  });

  useEffect(() => {
    headline("");
  }, []);

  const sortCast = (show) => {
    if (show.productionCast.productionTotalCastSorted) {
      let allCastString = show.productionCast.productionTotalCastSorted
        .replace(/,}/gm, "}")
        .replace(/,]/gm, "]");
      let allCast = JSON.parse(allCastString);

      let jobtypes = {};
      let castByJob = [];

      for (let role of allCast) {
        if (
          (ignoreJobs[show.productionDetails.productionId] &&
            !ignoreJobs[show.productionDetails.productionId].includes(
              role.role_name
            )) ||
          !ignoreJobs[show.productionDetails.productionId]
        ) {
          let sanitizedJob = replaceUmlaute(role.role_name)
            .trim()
            .replace(/[!.]/g, "")
            .replace(/[\s/&,]/g, "-")
            .replace(/-+/g, "-")
            .toLowerCase();

          jobtypes[sanitizedJob] = role.role_name;

          let filteredPeople = role.people.filter(
            (x) => !x.understudy_rank || x.understudy_rank === "1"
          );

          let castArray = getPeopleWithAlternatives(filteredPeople, role);

          let currentJob = castByJob.find(
            (x) => x.sanitizedJob === sanitizedJob
          );

          if (currentJob) {
            let newPeople = [...currentJob.people, ...castArray];
            const arrUniq = [
              ...new Map(newPeople.map((v) => [v.id, v])).values(),
            ];
            currentJob.people = arrUniq;
          } else {
            let newJob = {
              jobTitle: role.role_name,
              sanitizedJob: sanitizedJob,
              sort: role.role_sort,
              people: castArray,
            };
            castByJob.push(newJob);
          }
        }
      }

      setJobMap(jobtypes);

      castByJob.map((job) => {
        const sortedPeople = job.people.sort(sortPeople);

        return {
          ...job,
          people: sortedPeople,
        };
      });

      castByJob.sort((a, b) => {
        if (Number(a.sort) < Number(b.sort)) {
          return -1;
        } else if (Number(a.sort) > Number(b.sort)) {
          return 1;
        }

        return 0;
      });

      setCast(castByJob);
    } else if (show.productionCast.productionTotalCast) {
      let allCastString = show.productionCast.productionTotalCast
        .replace(/,}/gm, "}")
        .replace(/,]/gm, "]");
      let allCast = JSON.parse(allCastString, (key, value) => {
        return value;
      });

      let jobtypes = {};
      let castByJob = [];

      allCast.forEach((jobtype) => {
        Object.entries(jobtype).forEach(([job, cast]) => {
          if (
            (ignoreJobs[show.productionDetails.productionId] &&
              !ignoreJobs[show.productionDetails.productionId].includes(job)) ||
            !ignoreJobs[show.productionDetails.productionId]
          ) {
            let sanitizedJob = replaceUmlaute(job)
              .trim()
              .replace(/[\s/&,]/g, "-")
              .replace(/-+/g, "-")
              .toLowerCase();

            jobtypes[sanitizedJob] = job;

            let castArray = Object.entries(cast).map(([key, value]) => {
              return { id: key, name: value };
            });

            castArray.sort((a, b) => {
              if (
                allCastString.indexOf(a.name) < allCastString.indexOf(b.name)
              ) {
                return -1;
              }
              if (
                allCastString.indexOf(a.name) > allCastString.indexOf(b.name)
              ) {
                return 1;
              }
              return 0;
            });

            let currentJob = castByJob.find(
              (x) => x.sanitizedJob === sanitizedJob
            );

            if (currentJob) {
              let newPeople = [...currentJob.people, ...castArray];
              const arrUniq = [
                ...new Map(newPeople.map((v) => [v.id, v])).values(),
              ];
              currentJob.people = arrUniq;
            } else {
              let newJob = {
                jobTitle: job,
                sanitizedJob: sanitizedJob,
                people: castArray,
              };
              castByJob.push(newJob);
            }
          }
        });
      });

      setJobMap(jobtypes);
      setCast(castByJob);
    } else {
      setCast(null);
    }
  };

  const venue = useMemo(() => {
    if (data && data.show) {
      return (
        data.show.productionDetails.stageId ||
        data.show.productionInfo.venue ||
        null
      );
    }
  }, [data]);

  useEffect(() => {
    if (data && titleRef.current) {
      const { offsetHeight } = titleRef.current;
      setTitleHeight(offsetHeight);
    }
  }, [titleRef, data]);

  if (error || isActive === false) {
    return <Redirect to={`/${locale}/404`} />;
  }

  if (loading) {
    return <Loader />;
  }

  if (data && data.show) {
    return (
      <>
        <SeoTags title={replaceBacktick(data.show.title)} seo={data.show.seo} />
        <nav className="screenreader">
          <HashLink to="#crew">
            <FormattedMessage id="anchor.cast" defaultMessage="Crew" />
          </HashLink>
          <HashLink to="#events">
            <FormattedMessage id="anchor.events" defaultMessage="Events" />
          </HashLink>
        </nav>
        <main
          className={`show ${getVenueClass(
            data.show.productionInfo.isFestival ? "festival" : venue
          )}`}
          key={data.show.id}
        >
          <div className="topImage" aria-hidden>
            <Gallery
              data={data.show.gallery}
              videos={data.show.productionVideos}
            />
          </div>
          <div className="titleSection" ref={titleRef}>
            <h1>{replaceBacktick(data.show.title)}</h1>
            <div className="titleInfos">
              {data.show.productionDetails.titleAddition &&
                data.show.productionDetails.titleAddition.length > 0 && (
                  <span>
                    <span role="separator" className="divider">
                      <span aria-hidden>&nbsp;&bull;&nbsp;</span>
                    </span>
                    <h2>{parse(data.show.productionDetails.titleAddition)} </h2>
                  </span>
                )}
              {data.show.productionDetails.subtitle &&
                data.show.productionDetails.subtitle.length > 0 && (
                  <span>
                    <span role="separator" className="divider">
                      <span aria-hidden>&nbsp;&bull;&nbsp;</span>
                    </span>
                    <h2>{parse(data.show.productionDetails.subtitle)} </h2>
                  </span>
                )}
              {data.show.productionCast &&
                data.show.productionCast.productionAuthors && (
                  <span>
                    {data.show.productionInfo.authorBullet && (
                      <span role="separator" className="divider">
                        <span aria-hidden>&nbsp;&bull;&nbsp;</span>
                      </span>
                    )}
                    <FormattedMessage id="show.by" defaultMessage="von" />
                    &nbsp;{parse(
                      data.show.productionCast.productionAuthors
                    )}{" "}
                  </span>
                )}
              {(data.show.productionDetails.originalTextDe ||
                data.show.productionDetails.originalTextEn) && (
                <>
                  {data.show.productionInfo.authorBullet &&
                    !(
                      data.show.productionCast &&
                      data.show.productionCast.productionAuthors
                    ) && (
                      <span role="separator" className="divider">
                        <span aria-hidden>&nbsp;&bull;&nbsp;</span>
                      </span>
                    )}
                  <span>
                    <IntText
                      de={data.show.productionDetails.originalTextDe}
                      en={data.show.productionDetails.originalTextEn}
                      showOtherLang={true}
                    />
                  </span>
                </>
              )}
              {data.show.productionDetails.werkeAddition && (
                <span>
                  <span role="separator" className="divider">
                    <span aria-hidden>&nbsp;&bull;&nbsp;</span>
                  </span>
                  <span>
                    {parse(data.show.productionDetails.werkeAddition)}{" "}
                  </span>
                </span>
              )}
              {venue && venue !== String(117) && (
                <span className="nobreak">
                  <span role="separator" className="divider">
                    <span aria-hidden>&nbsp;&bull;&nbsp;</span>
                  </span>
                  <FormattedMessage
                    id={`venue.${venues[venue]}`}
                    defaultMessage={venues[venue]}
                  />
                </span>
              )}
            </div>
          </div>
          <section className="mainContent">
            {data && (
              <div className="descriptionWrapper">
                <div ref={description} className="description">
                  {!crewFirst.includes(
                    Number(data.show.productionDetails.productionId)
                  ) &&
                    data.show.text_content && (
                      <div>
                        <IntText
                          de={data.show.text_content.contentDe}
                          en={data.show.text_content.contentEn}
                          withFallback
                        />
                      </div>
                    )}
                  {(data.show.productionInfo.additionalInfoDe ||
                    peopleData ||
                    cast) &&
                    !data.show.productionDetails.isSeries && (
                      <>
                        {cast && cast.length > 0 ? (
                          <div className="people" id="crew">
                            {cast.map(
                              (jobtype) =>
                                jobtype.sanitizedJob !== "author" &&
                                !(
                                  jobtype.sanitizedJob === "director" &&
                                  (peopleData["text-director"].length ||
                                    peopleData["concept-director"].length)
                                ) &&
                                jobtype.people.length > 0 && (
                                  <div
                                    key={jobtype.sanitizedJob}
                                    className={`jobtype ${
                                      multiLine.includes(jobtype.sanitizedJob)
                                        ? "addMargin"
                                        : ""
                                    }`}
                                  >
                                    {jobtype.people && (
                                      <h4>
                                        <FormattedMessage
                                          id={`jobtype.${
                                            jobtype.sanitizedJob === "actor"
                                              ? "with"
                                              : jobtype.sanitizedJob
                                          }`}
                                          values={{
                                            count: jobtype.people.length,
                                            mask:
                                              data.show.productionDetails
                                                .productionId === "812"
                                                ? "Mask"
                                                : "Make-Up",
                                          }}
                                          defaultMessage={
                                            jobMap[jobtype.sanitizedJob]
                                          }
                                        />
                                        :{" "}
                                      </h4>
                                    )}
                                    {jobtype.people.map(
                                      (person) =>
                                        person && (
                                          <span
                                            className="person"
                                            key={person.id + person.name}
                                          >
                                            <PersonName
                                              id={person.id}
                                              name={person.name}
                                              link
                                            />
                                            {person.alternatives &&
                                              person.alternatives.length > 0 &&
                                              person.alternatives.map((alt) => (
                                                <React.Fragment
                                                  key={alt.id + alt.name}
                                                >
                                                  <span>/</span>
                                                  <PersonName
                                                    id={alt.id}
                                                    name={alt.name}
                                                    link
                                                  />
                                                </React.Fragment>
                                              ))}
                                            <span className="separator">
                                              ,{" "}
                                            </span>
                                          </span>
                                        )
                                    )}
                                  </div>
                                )
                            )}
                          </div>
                        ) : peopleData && Object.keys(peopleData).length > 0 ? (
                          <div className="people" id="crew">
                            {Object.keys(peopleData).map(
                              (jobtype) =>
                                jobtype !== "author" &&
                                !(
                                  jobtype === "director" &&
                                  (peopleData["text-director"].length ||
                                    peopleData["concept-director"].length)
                                ) &&
                                peopleData[jobtype].length > 0 && (
                                  <div
                                    key={jobtype}
                                    className={`jobtype ${
                                      multiLine.includes(jobtype)
                                        ? "addMargin"
                                        : ""
                                    }`}
                                  >
                                    <h4>
                                      <FormattedMessage
                                        id={`jobtype.${
                                          jobtype === "actor" ? "with" : jobtype
                                        }`}
                                        values={{
                                          count: peopleData[jobtype].length,
                                          mask:
                                            data.show.productionDetails
                                              .productionId === "812"
                                              ? "Mask"
                                              : "Make-Up",
                                        }}
                                        defaultMessage={jobtype}
                                      />
                                      :&nbsp;
                                    </h4>
                                    {peopleData[jobtype].map((person) => (
                                      <span
                                        className="person"
                                        key={person.id + person.name}
                                      >
                                        <PersonName
                                          id={person.id}
                                          name={person.name}
                                          link
                                        />
                                        <span className="separator">, </span>
                                      </span>
                                    ))}
                                  </div>
                                )
                            )}
                          </div>
                        ) : null}
                        {crewFirst.includes(
                          Number(data.show.productionDetails.productionId)
                        ) &&
                          data.show.text_content && (
                            <div>
                              <IntText
                                de={data.show.text_content.contentDe}
                                en={data.show.text_content.contentEn}
                                withFallback
                              />
                            </div>
                          )}
                        {data.show.productionInfo &&
                          data.show.productionInfo.additionalInfoDe && (
                            <p className="additionalInfo">
                              <IntText
                                de={data.show.productionInfo.additionalInfoDe}
                                en={data.show.productionInfo.additionalInfoEn}
                              />
                            </p>
                          )}
                      </>
                    )}
                  <Logos logos={data.show.gallery.logos} />
                  {((data.show.gallery && data.show.gallery.gallery) ||
                    data.show.productionVideos) && (
                    <MediaCredits
                      galleryImages={data.show.gallery.gallery}
                      vidPosition={data.show.gallery.vidPosition}
                      videoData={data.show.productionVideos}
                    />
                  )}
                </div>
              </div>
            )}
            <div
              id="events"
              className="calendarWrapper"
              style={{ top: `${titleHeight + 25}px` }}
            >
              <EventCalendar
                key={`show-${data.show.productionDetails.productionId}`}
                productionId={data.show.productionDetails.productionId}
                venueId={
                  data.show.productionInfo.isFestival
                    ? "festival"
                    : Number(venue)
                }
                type="byShow"
              />
              {!showArchive &&
                data.show.productionInfo.premiereEvent &&
                eventIsInPast(data.show.productionInfo.premiereEvent) && (
                  <EventCalendar
                    key={`show-${data.show.productionDetails.productionId}`}
                    productionId={data.show.productionDetails.productionId}
                    venueId={
                      data.show.productionInfo.isFestival
                        ? "festival"
                        : Number(venue)
                    }
                    type="noQuery"
                    data={[data.show.productionInfo.premiereEvent]}
                    withPremieres
                    withSeparator
                  />
                )}
              {showArchive && (
                <EventCalendar
                  key={`archive-${data.show.productionDetails.productionId}`}
                  productionId={data.show.productionDetails.productionId}
                  type="byShow"
                  venueId={
                    data.show.productionInfo.isFestival
                      ? "festival"
                      : Number(venue)
                  }
                  withArchive
                  withSeparator
                />
              )}
              {linkBack && linkBackKey && (
                <Link to={linkBack} className="linkBack">
                  &gt;&nbsp;
                  <FormattedMessage
                    id={`link.back.${linkBackKey}`}
                    defaultMessage={`zur Übersicht ${linkBackKey}`}
                  />
                </Link>
              )}
            </div>
          </section>
        </main>
      </>
    );
  } else {
    return null;
  }
};

export default Show;
