import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import "./LanguageSwitcher.scss";
import { IntlContext, availableLanguages } from "../../IntlWrapper/IntlWrapper";
import { getRouteKeys } from "../../route-utils";
import { Link, useRouteMatch, useLocation } from "react-router-dom";

const LanguageSwitcher = () => {
  const { pathname } = useLocation();
  const { locale } = useIntl();
  const { intlSlugs } = useContext(IntlContext);
  const [links, setLinks] = useState();

  useEffect(() => {
    if (intlSlugs) {
      let newLinks = {};

      for (let lang of availableLanguages) {
        newLinks[lang] = translateUrl(pathname, lang);
      }

      setLinks(newLinks);

      function translateUrl(path, option) {
        let routeParts = path.split("/");
        let newPath = "";

        for (let part of routeParts) {
          let intlObj = getRouteKeys(locale, part);

          if (intlObj && intlObj[option]) {
            newPath = `${newPath}/${intlObj[option]}`;
          } else if (intlSlugs[locale] === part) {
            newPath = `${newPath}/${intlSlugs[option]}`;
          } else if (part.length) {
            newPath = `${newPath}/${part}`;
          }
        }

        let newLocation = newPath.replace(locale, option);
        return newLocation;
      }
    }
  }, [intlSlugs, locale, pathname]);

  return (
    <>
      {links && (
        <Link
          to={links[availableLanguages.find((x) => x !== locale)]}
          className="lang-option"
        >
          <FormattedMessage
            id={`lang.${availableLanguages.find((x) => x !== locale)}`}
            defaultMessage={availableLanguages.find((x) => x !== locale)}
          />
        </Link>
      )}
    </>
  );
};

export default LanguageSwitcher;
