import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie11";
// import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import IntlWrapper from "./i18n/IntlWrapper/IntlWrapper";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import { getCookieConsentValue } from "react-cookie-consent";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider, ApolloClient, gql, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { cache } from "./cache";

export const typeDefs = gql`
  extend type Query {
    intlSlugs: Slugs!
    activeMenu: String!
    headline: String!
    activeVenue: String!
    selectedVenues: [Number!]!
    timeFrame: TimeFrame!
    loadedMonths: [Month!]!
  }

  type TimeFrame {
    start: Month!
    end: Month!
  }

  type Month {
    y: String!
    m: String!
    even: boolean
  }

  type Slugs {
    en: String!
    de: String!
  }
`;

const httpLink = new BatchHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  batchMax: 5, // No more than 5 operations per batch
  batchInterval: 20,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  // credentials: "include",
  // useGETForQueries: true,
  // fetchOptions: {
  //   mode: "no-cors",
  // },
  // headers: {
  //   authorization: "Bearer secretvbauth",
  // },
  cache,
  typeDefs,
});

var _paq = (window._paq = window._paq || []);
/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
_paq.push(["requireConsent"]);
_paq.push(["requireCookieConsent"]);
_paq.push(["trackPageView"]);
_paq.push(["enableLinkTracking"]);
(function () {
  var u = "https://volksbuehneberlin.matomo.cloud/";
  _paq.push(["setTrackerUrl", u + "matomo.php"]);
  _paq.push(["setSiteId", "1"]);
  var d = document,
    g = d.createElement("script"),
    s = d.getElementsByTagName("script")[0];
  g.type = "text/javascript";
  g.async = true;
  g.src = "//cdn.matomo.cloud/volksbuehneberlin.matomo.cloud/matomo.js";
  s.parentNode.insertBefore(g, s);
})();

const instance = createInstance({
  urlBase: "https://volksbuehneberlin.matomo.cloud/",
  siteId: 1,
  userId: "UID76903202", // optional, default value: `undefined`.
  trackerUrl: "https://volksbuehneberlin.matomo.cloud/matomo.php", // optional, default value: `${urlBase}matomo.php`
  srcUrl: "//cdn.matomo.cloud/volksbuehneberlin.matomo.cloud/matomo.js", // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: false, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: getCookieConsentValue() !== true,
    setSecureCookie: true,
    setRequestMethod: "POST",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <IntlWrapper>
      <MatomoProvider value={instance}>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </MatomoProvider>
    </IntlWrapper>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
