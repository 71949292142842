import React, { useState } from "react";
import { Link } from "react-router-dom";
import MainMenu from "../mainMenu/MainMenu";
import { Button } from "reakit";
import LanguageSwitcher from "../../i18n/components/languageSwitcher/LanguageSwitcher";
import Search from "../search/Search";
import "./Header.scss";
import SubMenu from "../subMenu/SubMenu";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import { useResizer } from "../../customHooks";
import { useIntl } from "react-intl";
import MonthSelector from "../monthSelector/MonthSelector";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [openedMenu, setOpenedMenu] = useState();
  const { locale, formatMessage } = useIntl();
  const isMobile = useResizer();

  return (
    <header className={`mainHeader ${isMobile && menuOpen ? "menuOpen" : ""}`}>
      <div className="top">
        <Link
          to={`/${locale}/${formatMessage({
            id: "route.programme",
          })}`}
          className="logo"
          aria-label="Volksbühne am Rosa-Luxemburg-Platz"
        />
        <h1 className="screenreader">Volksbühne am Rosa-Luxemburg-Platz</h1>
        {isMobile && (
          <Button
            as="h3"
            aria-expanded={menuOpen}
            aria-label={
              menuOpen
                ? formatMessage({
                    id: "aria.close-menu",
                    defaultMessage: "Close Menu",
                  })
                : formatMessage({
                    id: "aria.menu",
                    defaultMessage: "Menu",
                  })
            }
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {menuOpen ? <CloseIcon /> : <MenuIcon />}
          </Button>
        )}
        {!isMobile && <MainMenu />}
      </div>
      {!isMobile && (
        <div className="settings">
          <SubMenu />
          <div className="langSearch">
            <MonthSelector />
            <LanguageSwitcher />
            <Search onClose={() => {}} />
          </div>
        </div>
      )}
      {isMobile && (
        <div className="mobileMenu">
          <div className="top">
            <Link to="/" className="logo" aria-label="Home" />
            <Button as="h3" aria-hidden onClick={() => setMenuOpen(false)}>
              <CloseIcon />
            </Button>
          </div>
          <MainMenu closeMenu={() => setMenuOpen(false)} hidden={!menuOpen} />
          <div className="settings">
            <MonthSelector
              mobile={true}
              toggleOpen={setOpenedMenu}
              openedMenu={openedMenu}
            />
            <LanguageSwitcher />
            <Search
              onClose={() => {
                setMenuOpen(false);
              }}
              toggleOpen={setOpenedMenu}
              openedMenu={openedMenu}
              mobile={true}
            />
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
