import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";
import "./Leporello.scss";
import {
  combineVenues,
  combineVenuesMobile,
  isGrossesHaus,
} from "../../i18n/route-utils";
import LepoRow from "./LepoRow/LepoRow";
import {
  GET_ACTIVE_VENUES,
  GET_EVENTS_BETWEEN,
  GET_INITIAL_SETTINGS,
  GET_SETTINGS,
  GET_TIME_FRAME,
} from "../../queries";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useResizer } from "../../customHooks";
import Loader from "../atoms/loader/Loader";
import {
  eventCancelledThea,
  isFestiwalla,
  specialLepoSections,
  todayDateString,
} from "../../utilities";

const getDays = (m, y, isMobile) => {
  // m - 1 because moment months start at 0 (being January)
  let activeMonth = moment()
    .startOf("month")
    .set({ month: m - 1, year: y });
  let allDays = [];

  const allVenues = isMobile ? combineVenuesMobile : combineVenues;

  for (let i = 1; i <= activeMonth.daysInMonth(); i++) {
    let dayObj = {
      dateString: `${y}${m}${i > 9 ? i : `0${i}`}`,
      date: activeMonth.clone().set("date", i),
      rows: 1,
      even: false,
      venues: allVenues.map((v) => ({ id: v.id, events: [] })),
    };
    allDays.push(dayObj);
  }
  return allDays;
};

const stageCheck = (compareStage, mainStage) => {
  if (mainStage === 117) {
    return isGrossesHaus.includes(compareStage);
  } else {
    return compareStage === mainStage;
  }
};

const sortEvents = (events, stageId) => {
  events.sort((a, b) => {
    let rtn = 0;

    if (a.eventDetails.stageId === 163) {
      rtn = 1;
    } else if (b.eventDetails.stageId === 163) {
      rtn = -1;
    } else if (a.eventDetails.stageId !== b.eventDetails.stageId) {
      if (stageCheck(a.eventDetails.stageId, stageId)) {
        rtn = -1;
      } else if (stageCheck(b.eventDetails.stageId, stageId)) {
        rtn = 1;
      }
    } else {
      if (
        a.event_cancellation &&
        a.event_cancellation.cancelled &&
        !(b.event_cancellation && b.event_cancellation.cancelled)
      ) {
        return 1;
      }

      if (
        b.event_cancellation &&
        b.event_cancellation.cancelled &&
        !(a.event_cancellation && a.event_cancellation.cancelled)
      ) {
        return -1;
      }
    }

    if (rtn === 0) {
      if (a.eventDetails.startTime <= b.eventDetails.startTime) {
        rtn = -1;
      } else {
        rtn = 1;
      }
    }

    return rtn;
  });

  return events;
};
//TODO: Add aria-labelledby to venue items and specify date and venue as labels

const Leporello = ({
  month,
  year,
  monthId,
  setDataReady,
  scrollableDiv,
  fragment = false,
  scrollOnePx,
  getIsInSeason,
  loadViaParams,
  // startEven,
  // setEvenOdd,
}) => {
  const [totalRows, setTotalRows] = useState();
  // const [activeVenue, setActiveVenue] = useState("grosses-haus");
  const isMobile = useResizer();
  const days = getDays(month, year, isMobile);
  const [startAt, setStartAt] = useState();
  const [dayRows, setDayRows] = useState(days);
  const [prevViewMobile, setPrevViewMobile] = useState(isMobile);

  const { data: settings } = useQuery(GET_INITIAL_SETTINGS, {
    onCompleted: (data) => {
      const today = moment();
      let start = moment(data.vbSetting.vb_custom_settings.startmonth);
      setStartAt(today.isBefore(start) ? start : today);
    },
  });

  const { data: activeVenues } = useQuery(GET_ACTIVE_VENUES);

  const { data: eventData, loading } = useQuery(GET_EVENTS_BETWEEN, {
    variables: {
      start: `${year}${month}00`,
      end: `${year}${month}31`,
    },
    onCompleted: (data) => {
      const filteredNodes = data.events.nodes.filter(
        (x) => !eventCancelledThea(x)
      );
      mapDays(filteredNodes);
      if (!loadViaParams) setDataReady(true);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (eventData && isMobile !== prevViewMobile) {
      const filteredNodes = eventData.events.nodes.filter(
        (x) => !eventCancelledThea(x)
      );
      mapDays(filteredNodes);
      if (!loadViaParams) setDataReady(true);
    }

    setPrevViewMobile(isMobile);
  }, [isMobile, prevViewMobile]);

  // const grafikStartseite = useMemo(() => {
  //   let grafik = null;

  //   if (settings) {
  //     const normal = settings.vbSetting.vb_custom_settings.grafikStartseite;
  //     const mobile =
  //       settings.vbSetting.vb_custom_settings.grafikStartseiteMobile;

  //     let useGrafik = isMobile ? mobile : normal;
  //     let other = isMobile ? normal : mobile;

  //     if (!useGrafik && other) {
  //       useGrafik = other;
  //       other = null;
  //     }

  //     if (useGrafik) {
  //       grafik = {
  //         url: useGrafik.mediaItemUrl || (other ? other.mediaItemUrl : null),
  //         link:
  //           useGrafik.mediaTexts.mediaLink ||
  //           (other ? other.mediaTexts.mediaLink : null),
  //         alt: useGrafik.altText || (other ? other.altText : null),
  //       };
  //     }
  //   }

  //   return grafik;
  // }, [settings, isMobile]);

  useEffect(() => {
    if (loading && !loadViaParams) {
      setDataReady(false);
    }
  }, [loading, setDataReady, loadViaParams]);

  // useEffect(() => {
  //   let endEven = startEven ? !!(totalRows % 2 === 0) : !!(totalRows % 2 !== 0);
  //   setEvenOdd(year, month, startEven, endEven);
  // }, [totalRows]);

  const mapEvents = (events) =>
    new Promise((resolve) => {
      let newDays = [...days];

      for (let event of events) {
        let dayObj = newDays.find(
          (element) =>
            element.dateString === String(event.eventDetails.filterableDate)
        );

        const findInVenues = isMobile ? combineVenuesMobile : combineVenues;

        let venueObj = findInVenues.find((x) =>
          x.venues.includes(Number(event.eventDetails.stageId))
        );

        if (venueObj && venueObj.id) {
          let venueArr = dayObj.venues.find((v) => v.id === venueObj.id);
          venueArr.events.push(event);
        }
      }
      resolve(newDays);
    });

  const mapDays = (data) => {
    mapEvents(data).then((newDays) => {
      let totalRows = 1;

      for (let day of newDays) {
        let hasEvent = false;
        for (let venue of day.venues) {
          venue.events = sortEvents(venue.events, venue.id);
          day.rows =
            venue.events.length > day.rows ? venue.events.length : day.rows;

          if (venue.events.length > 0) {
            hasEvent = true;
          }
        }

        // let setEven = startEven
        //   ? !!(totalRows % 2 === 0)
        //   : !!(totalRows % 2 !== 0);
        let setEven = !!(totalRows % 2 === 0);
        day.even = setEven;
        day.empty = !hasEvent;

        if (!(day.empty && !getIsInSeason(day.dateString))) {
          totalRows = totalRows + day.rows;
        }

        if (day === newDays[newDays.length - 1]) {
          setTotalRows(totalRows);
        }
      }
      setDayRows(newDays);
    });
  };

  const showDay = (day) => {
    const data = dayRows.find((x) => x.dateString === day.dateString);

    const dayDate = moment(day.dateString);
    const isInSeason = getIsInSeason(day.dateString);
    const end =
      process.env.REACT_APP_ENV === "DEV"
        ? moment(settings.vbSetting.vb_custom_settings.endmonthDev)
        : moment(settings.vbSetting.vb_custom_settings.endmonth);

    const isBeforeEnd = dayDate.isSameOrBefore(end);
    const festiwalla =
      isFestiwalla(day.dateString) && isFestiwalla(todayDateString);
    // load if today or after or if whole month has been loaded (up)
    const shouldLoad =
      dayDate.isAfter(moment().subtract(1, "days")) ||
      festiwalla ||
      !fragment ||
      loadViaParams;

    return !!((isInSeason || !data.empty) && shouldLoad && isBeforeEnd);
  };

  if (loading) {
    return <Loader />;
  }

  return eventData && eventData.events ? (
    <div
      className="leporello"
      id={monthId}
      role="grid"
      aria-readonly
      aria-labelledby={`lepo-month-${month}`}
    >
      <h2 className="month screenreader" id={`lepo-month-${month}`}>
        <FormattedDate
          value={moment().set({ month: Number(month) - 1, year: year })}
          month="long"
          year="numeric"
        />
      </h2>
      {(!(fragment && moment().month() === month - 1) ||
        moment().date() === 1) && (
        <h2 className="month" aria-hidden>
          <FormattedDate
            value={moment().set({ month: Number(month) - 1, year: year })}
            month="long"
            year="numeric"
            aria-hidden
          />
        </h2>
      )}
      {days &&
        days.map((day, i) =>
          showDay(day) ? (
            <React.Fragment key={day.dateString}>
              {Object.keys(specialLepoSections).includes(day.dateString) && (
                <>
                  <h2 className="month">
                    <div>
                      <FormattedMessage
                        id={`lepo-section.${
                          specialLepoSections[day.dateString]
                        }`}
                        defaultMessage={specialLepoSections[day.dateString]}
                      />
                    </div>
                    <div className="side-info">
                      <span>
                        <FormattedMessage
                          id={`lepo-section.${
                            specialLepoSections[day.dateString]
                          }.additional-info-1`}
                          defaultMessage=""
                        />
                      </span>
                      {isMobile ? <span> &bull;&nbsp;</span> : <br />}
                      {isMobile ? (
                        <FormattedMessage
                          id={`lepo-section.${
                            specialLepoSections[day.dateString]
                          }.additional-info-2-mobile`}
                          defaultMessage=""
                        />
                      ) : (
                        <FormattedMessage
                          id={`lepo-section.${
                            specialLepoSections[day.dateString]
                          }.additional-info-2`}
                          defaultMessage=""
                        />
                      )}
                    </div>
                  </h2>
                </>
              )}
              {/* {settings &&
                moment().isSame(day.date, "day") &&
                grafikStartseite &&
                (grafikStartseite.link ? (
                  <a
                    href={grafikStartseite.link}
                    target={
                      grafikStartseite.link.includes("volksbuehne.berlin")
                        ? "_self"
                        : "_blank"
                    }
                    rel="noreferrer"
                  >
                    <img
                      className="lepoImg"
                      onLoad={() => scrollOnePx("top")}
                      src={grafikStartseite.url}
                      alt={grafikStartseite.alt || " "}
                    />
                  </a>
                ) : (
                  <img
                    className="lepoImg"
                    onLoad={() => scrollOnePx("top")}
                    src={grafikStartseite.url}
                    alt={grafikStartseite.alt || " "}
                  />
                ))} */}
              <LepoRow
                date={day.date}
                dateString={day.dateString}
                key={day.dateString}
                data={dayRows ? dayRows[i] : {}}
                selectedVenues={activeVenues.selectedVenues}
                scrollableDiv={scrollableDiv}
                startAt={startAt}
              />
            </React.Fragment>
          ) : null
        )}
    </div>
  ) : null;
};

// export default Leporello;
export default React.memo(Leporello);
