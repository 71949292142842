import { gql } from "@apollo/client";

const EVENT_DATA = gql`
  fragment EventData on Event {
    databaseId
    eventDetails {
      activityId
      customInfo
      endTime
      filterableDate
      genre
      productionId
      stageId
      startTime
      title
      subtitle
      productionTitleAddition
      eventDetailsDirector
      eventDetailsWriter
      originalTextDe
      originalTextEn
      surtitles
      surtitlesEvent
      eventTitle
      originalTitle
      eventType
      note
      noteLong
      seriesPart
      planningStatus
    }
    parentLinks {
      productionLink
      singleEventLink
    }
    eventDetailOverrides {
      authorAndDirector
    }
    programmeInfo {
      lepoInfo
      eventCategory
      calendarSubtitle
    }
    event_cancellation {
      cancellationMode
      cancelled
      ersatztermin {
        ... on Event {
          id
          eventId
          eventDetails {
            startTime
          }
        }
      }
    }
    eventim_import {
      eventimAvailableTickets
      eventimCapacity
      eventimStatus
    }
    eventim_data {
      eventimId
      eventimUrl
      noTicketLink
      soldOut
    }
    showOnline {
      activateOn
      isOnline
    }
  }
`;

export const GET_INITIAL_SETTINGS = gql`
  query GetSettings {
    vbSetting(id: "volksbuhne-einstellungen", idType: SLUG) {
      seo {
        seoDescription
        seoKeywords
        seoDescriptionEn
        seoKeywordsEn
      }
      vb_custom_settings {
        hideAktuelles
        hidePage1
        startseite
        startmonth
        endmonth
        endmonthDev
      }
    }
  }
`;

export const GET_CATEGORY_OVERVIEW_SETTINGS = gql`
  query GetSettings {
    vbSetting(id: "volksbuhne-einstellungen", idType: SLUG) {
      vb_custom_settings {
        presstext
        presstextEn
        seriesOrder {
          ... on Show {
            title
          }
        }
        currentSeason {
          ... on Spielzeit {
            id
            spielzeitInfo {
              spielzeitStart
              spielzeitEnd
            }
          }
        }
      }
    }
  }
`;

export const GET_VB_PRESS_FILES = gql`
  query GetSettings {
    vbSetting(id: "volksbuhne-einstellungen", idType: SLUG) {
      pressFiles {
        pressPhotos {
          mediaItemUrl
          mediaTexts {
            copyright
            descriptionEn
            altTextEn
          }
          description
          altText
          sourceUrl(size: LARGE)
          slug
          mimeType
        }
      }
    }
  }
`;

export const GET_SETTINGS = gql`
  query GetSettings {
    vbSetting(id: "volksbuhne-einstellungen", idType: SLUG) {
      seo {
        seoDescription
        seoKeywords
        seoDescriptionEn
        seoKeywordsEn
      }
      vb_custom_settings {
        backupBanner {
          mediaItemUrl
        }
        hideAktuelles
        hidePage1
        startseite
        grafikStartseite {
          mediaItemUrl
          mediaTexts {
            mediaLink
          }
        }
        grafikStartseiteMobile {
          mediaItemUrl
          mediaTexts {
            mediaLink
          }
        }
        startmonth
        endmonth
        endmonthDev
        seriesOrder {
          ... on Show {
            title
          }
        }
        currentSeason {
          ... on Spielzeit {
            id
            spielzeitInfo {
              spielzeitStart
              spielzeitEnd
            }
          }
        }
        presstext
        presstextEn
      }
      pressFiles {
        pressPhotos {
          mediaItemUrl
          mediaTexts {
            copyright
            descriptionEn
            altTextEn
          }
          description
          altText
          sourceUrl(size: LARGE)
          slug
          mimeType
        }
      }
    }
  }
`;

const BANNER_DATA = gql`
  fragment BannerDataShow on Show {
    slug
    productionBanner {
      banner {
        mediaItemUrl
        altText
        mediaTexts {
          altTextEn
        }
      }
    }
  }
  fragment BannerDataEvent on SingleEvent {
    slug
    productionBanner {
      banner {
        mediaItemUrl
        altText
        mediaTexts {
          altTextEn
        }
      }
    }
  }
  fragment BannerDataVenue on VenuePage {
    slug
    productionBanner {
      banner {
        mediaItemUrl
        altText
        mediaTexts {
          altTextEn
        }
      }
    }
  }
`;

export const GET_BANNERS = gql`
  ${BANNER_DATA}
  query GetBanners {
    vbSetting(id: "volksbuhne-einstellungen", idType: SLUG) {
      vb_custom_settings {
        dailyBanners {
          bannerSelectionStart
          bannerOrder {
            day1 {
              ... on Show {
                ...BannerDataShow
              }
              ... on SingleEvent {
                ...BannerDataEvent
              }
              ... on VenuePage {
                ...BannerDataVenue
              }
            }
            day2 {
              ... on Show {
                ...BannerDataShow
              }
              ... on SingleEvent {
                ...BannerDataEvent
              }
              ... on VenuePage {
                ...BannerDataVenue
              }
            }
            day3 {
              ... on Show {
                ...BannerDataShow
              }
              ... on SingleEvent {
                ...BannerDataEvent
              }
              ... on VenuePage {
                ...BannerDataVenue
              }
            }
            day4 {
              ... on Show {
                ...BannerDataShow
              }
              ... on SingleEvent {
                ...BannerDataEvent
              }
              ... on VenuePage {
                ...BannerDataVenue
              }
            }
            day5 {
              ... on Show {
                ...BannerDataShow
              }
              ... on SingleEvent {
                ...BannerDataEvent
              }
              ... on VenuePage {
                ...BannerDataVenue
              }
            }
            day6 {
              ... on Show {
                ...BannerDataShow
              }
              ... on SingleEvent {
                ...BannerDataEvent
              }
              ... on VenuePage {
                ...BannerDataVenue
              }
            }
            day7 {
              ... on Show {
                ...BannerDataShow
              }
              ... on SingleEvent {
                ...BannerDataEvent
              }
              ... on VenuePage {
                ...BannerDataVenue
              }
            }
          }
        }
        dailyBannerShow {
          ... on Show {
            id
            slug
            productionBanner {
              banner {
                mediaItemUrl
                altText
                mediaTexts {
                  altTextEn
                }
              }
            }
          }
          ... on VenuePage {
            id
            slug
            productionBanner {
              banner {
                mediaItemUrl
                altText
                mediaTexts {
                  altTextEn
                }
              }
            }
          }
        }
        currentBanner {
          mediaItemUrl
          altText
          mediaTexts {
            altTextEn
            mediaLink
            mediaLinkEn
          }
        }
        dailyBanner {
          mediaItemUrl
          altText
          mediaTexts {
            altTextEn
            mediaLink
            mediaLinkEn
          }
        }
        backupBanner {
          mediaItemUrl
          altText
          mediaTexts {
            altTextEn
            mediaLink
            mediaLinkEn
          }
        }
      }
    }
  }
`;

// export const GET_SERIES_ORDER = gql`
//   query GetSettings {
//     vbSetting(id: "volksbuhne-einstellungen", idType: SLUG) {
//       vb_custom_settings {
//         seriesOrder {
//           ... on Show {
//             id
//           }
//         }
//       }
//     }
//   }
// `;

export const GET_SPIELZEITEN = gql`
  query GetSpielzeiten {
    spielzeiten {
      nodes {
        title
        slug
        spielzeitInfo {
          spielzeitEnd
          spielzeitStart
        }
      }
    }
  }
`;

export const GET_EVENTS_BETWEEN = gql`
  ${EVENT_DATA}
  query GetEventsBetween($start: String!, $end: String!) {
    events(
      where: {
        metaQuery: {
          metaArray: [
            {
              compare: GREATER_THAN_OR_EQUAL_TO
              key: "filterable_date"
              value: $start
            }
            {
              compare: LESS_THAN_OR_EQUAL_TO
              key: "filterable_date"
              value: $end
            }
          ]
        }
      }
      first: 300
    ) {
      nodes {
        ...EventData
      }
    }
  }
`;

export const GET_SPECIAL_EVENTS = gql`
  ${EVENT_DATA}
  query GetSpecialEvents($from: String!) {
    events(
      first: 25
      where: {
        metaQuery: {
          metaArray: [
            {
              compare: GREATER_THAN_OR_EQUAL_TO
              value: $from
              key: "filterable_date"
            }
            { compare: LIKE, key: "is_online", value: "active" }
          ]
          relation: AND
        }
      }
    ) {
      nodes {
        ...EventData
      }
    }
  }
`;

export const GET_AUDIO_EVENTS = gql`
  ${EVENT_DATA}
  query GetAudioEvents($start: String!, $end: String!) {
    events(
      first: 10
      where: {
        metaQuery: {
          metaArray: [
            {
              compare: GREATER_THAN_OR_EQUAL_TO
              key: "filterable_date"
              value: $start
            }
            {
              compare: LESS_THAN_OR_EQUAL_TO
              key: "filterable_date"
              value: $end
            }
            { compare: LIKE, key: "surtitles_event", value: "audiodeskription" }
          ]
          relation: AND
        }
      }
    ) {
      nodes {
        ...EventData
      }
    }
  }
`;

export const GET_SHOWS = gql`
  query GetShows {
    shows(
      first: 300
      where: {
        status: PUBLISH
        metaQuery: {
          metaArray: [
            { compare: EQUAL_TO, key: "show_in_repertoire", value: "1" }
          ]
        }
      }
    ) {
      nodes {
        productionDetails {
          productionId
          titleAddition
          subtitle
          werkeAddition
          originalTextDe
          originalTextEn
          isSeries
        }
        productionInfo {
          premiereEvent {
            ... on Event {
              eventDetails {
                startTime
                customInfo
              }
            }
          }
          plannedPremiere {
            premiereDate
            premiereType
          }
          venue
          showInRepertoire
          showInArchive
        }
        productionCast {
          productionDirectors
          productionAuthors
        }
        pressFiles {
          pressPhotos {
            mediaItemUrl
          }
        }
        theasoftState {
          newType
        }
        slug
        title
        showOnline {
          activateOn
          isOnline
        }
      }
    }
  }
`;

export const GET_SERIES = gql`
  query GetSeries {
    shows(
      first: 300
      where: {
        status: PUBLISH
        metaQuery: {
          metaArray: [{ compare: EQUAL_TO, key: "is_series", value: "1" }]
        }
      }
    ) {
      nodes {
        productionDetails {
          productionId
          titleAddition
          subtitle
          werkeAddition
          originalTextDe
          originalTextEn
          isSeries
        }
        productionInfo {
          premiereEvent {
            ... on Event {
              eventDetails {
                startTime
                customInfo
              }
            }
          }
          plannedPremiere {
            premiereDate
            premiereType
          }
          venue
          showInRepertoire
          showInArchive
        }
        productionCast {
          productionDirectors
          productionAuthors
        }
        pressFiles {
          pressPhotos {
            mediaItemUrl
          }
        }
        theasoftState {
          newType
        }
        slug
        title
        showOnline {
          activateOn
          isOnline
        }
      }
    }
  }
`;

export const GET_ARCHIVED_SHOWS = gql`
  query GetArchivedShows {
    shows(
      first: 300
      where: {
        metaQuery: {
          metaArray: { compare: EQUAL_TO, value: "1", key: "show_in_archive" }
        }
      }
    ) {
      nodes {
        productionDetails {
          productionId
          titleAddition
          subtitle
          werkeAddition
          originalTextDe
          originalTextEn
          isSeries
        }
        productionInfo {
          premiereEvent {
            ... on Event {
              eventDetails {
                startTime
                customInfo
              }
            }
          }
          plannedPremiere {
            premiereDate
            premiereType
          }
          venue
          showInRepertoire
          showInArchive
        }
        productionCast {
          productionDirectors
          productionAuthors
        }
        pressFiles {
          pressPhotos {
            mediaItemUrl
          }
        }
        theasoftState {
          newType
        }
        slug
        title
        showOnline {
          activateOn
          isOnline
        }
      }
    }
  }
`;

export const GET_SHOW = gql`
  ${EVENT_DATA}
  query GetShow($slug: ID!) {
    show(id: $slug, idType: SLUG) {
      id
      slug
      title
      seo {
        seoDescription
        seoKeywords
        seoDescriptionEn
        seoKeywordsEn
      }
      productionDetails {
        subtitle
        productionId
        titleAddition
        werkeAddition
        originalTextDe
        originalTextEn
        isSeries
        stageId
      }
      productionInfo {
        premiereEvent {
          ... on Event {
            id
            ...EventData
          }
        }
        plannedPremiere {
          premiereDate
          premiereType
        }
        additionalInfoDe
        additionalInfoEn
        venue
        authorBullet
        isFestival
      }
      theasoftState {
        newType
      }
      productionCast {
        productionAuthors
        productionChoir
        productionDanceChoir
        productionChoreography
        productionRealChoreo
        productionCostumes
        productionDirectors
        productionDramaturgy
        productionDramaturgyRt
        productionLighting
        productionLiveCamera
        productionLiveMusic
        productionMusic
        productionSetDesign
        productionActors
        productionDanceChoreo
        productionPerformanceChoreo
        productionCompositionSound
        productionVideo
        productionVideoConcept
        productionVideoDesign
        productionTextDirector
        productionConceptDirector
        productionSetCostumes
        productionAcrobat
        productionMusicCoach
        productionLightDesign
        productionTotalCast
        productionTotalCastSorted
      }
      gallery {
        vidPosition
        gallery {
          mediaItemUrl
          altText
          description
          mediaTexts {
            altTextEn
            copyright
            descriptionEn
          }
        }
        galleryMobile {
          mediaItemUrl
          altText
          description
          mediaTexts {
            altTextEn
            copyright
            descriptionEn
          }
        }
        logos {
          mediaItemUrl
          altText
          mediaTexts {
            altTextEn
            mediaLink
          }
        }
      }
      productionVideos {
        video1 {
          descriptionEn
          videoCopyright
          videoDescription
          videoUrl
          videoFile
        }
        video2 {
          descriptionEn
          videoCopyright
          videoDescription
          videoUrl
          videoFile
        }
        video3 {
          descriptionEn
          videoCopyright
          videoDescription
          videoUrl
          videoFile
        }
        video4 {
          descriptionEn
          videoCopyright
          videoDescription
          videoUrl
          videoFile
        }
        video5 {
          descriptionEn
          videoCopyright
          videoDescription
          videoUrl
          videoFile
        }
      }
      text_content {
        contentDe
        contentEn
      }
      showOnline {
        activateOn
        isOnline
      }
    }
  }
`;

export const GET_SHOW_WITH_PRESS = gql`
  query GetShowWithPress($slug: ID!) {
    show(id: $slug, idType: SLUG) {
      id
      slug
      title
      productionDetails {
        subtitle
        productionId
        titleAddition
        werkeAddition
        originalTextDe
        originalTextEn
      }
      productionInfo {
        premiereEvent {
          ... on Event {
            id
            eventDetails {
              startTime
            }
          }
        }
        plannedPremiere {
          premiereDate
          premiereType
        }
        venue
      }
      productionCast {
        productionAuthors
      }
      pressFiles {
        pressPhotos {
          mediaItemUrl
          mediaTexts {
            copyright
            descriptionEn
            altTextEn
          }
          description
          altText
          sourceUrl(size: LARGE)
          slug
          mimeType
        }
      }
      showOnline {
        activateOn
        isOnline
      }
    }
  }
`;

export const GET_PERSON_BY_ID = gql`
  query GetPersonById($id: String!) {
    persons(
      where: {
        metaQuery: {
          metaArray: { compare: EQUAL_TO, key: "person_id", value: $id }
        }
      }
    ) {
      nodes {
        person_import {
          lastName
          firstName
        }
        slug
      }
    }
  }
`;

export const GET_SHOW_BY_ID = gql`
  query GetShowById($id: String!) {
    shows(
      where: {
        metaQuery: {
          metaArray: { compare: EQUAL_TO, key: "production_id", value: $id }
        }
      }
    ) {
      nodes {
        slug
        title
        showOnline {
          activateOn
          isOnline
        }
        productionInfo {
          venue
        }
        productionDetails {
          isSeries
        }
        productionBanner {
          banner {
            mediaItemUrl
            altText
            mediaTexts {
              altTextEn
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_POSTS = gql`
  query SEARCH_POSTS($search: String!) {
    persons(where: { search: $search }, first: 300) {
      nodes {
        title
        id
        slug
      }
    }
    shows(first: 100, where: { search: $search }) {
      nodes {
        title
        id
        slug
        showOnline {
          activateOn
          isOnline
        }
        productionInfo {
          venue
        }
        productionDetails {
          isSeries
        }
      }
    }
    singleEvents(first: 300, where: { search: $search }) {
      nodes {
        slug
        id
        title
        showOnline {
          activateOn
          isOnline
        }
      }
    }

    pages(first: 100, where: { search: $search }) {
      nodes {
        title
        id
        slug
        titles {
          slugEn
        }
      }
    }
  }
`;

export const SEARCH_SHOWS_WITH_PREMIERES = gql`
  ${EVENT_DATA}
  query SearchShowsWithPremieres($search: String!) {
    shows(first: 100, where: { status: PUBLISH, search: $search }) {
      nodes {
        productionInfo {
          premiereEvent {
            ... on Event {
              ...EventData
            }
          }
          plannedPremiere {
            premiereDate
            premiereType
          }
        }
        showOnline {
          activateOn
          isOnline
        }
      }
    }
  }
`;

export const FIND_VENUE_PREMIERES = gql`
  ${EVENT_DATA}
  query FindVenuePremieres($venueId: String!) {
    shows(
      first: 100
      where: {
        status: PUBLISH
        metaQuery: {
          metaArray: { compare: BETWEEN, key: "stage_id", value: $venueId }
        }
      }
    ) {
      nodes {
        title
        productionInfo {
          premiereEvent {
            ... on Event {
              ...EventData
            }
          }
          plannedPremiere {
            premiereDate
            premiereType
          }
        }
        showOnline {
          activateOn
          isOnline
        }
      }
    }
  }
`;

export const GET_EVENTS_OF_SHOW = gql`
  ${EVENT_DATA}
  query GetEventsOfShow(
    $productionId: String!
    $start: String!
    $end: String!
  ) {
    events(
      first: 100
      where: {
        metaQuery: {
          metaArray: [
            { compare: EQUAL_TO, key: "production_id", value: $productionId }
            {
              compare: GREATER_THAN_OR_EQUAL_TO
              key: "filterable_date"
              value: $start
            }
            {
              compare: LESS_THAN_OR_EQUAL_TO
              key: "filterable_date"
              value: $end
            }
          ]
        }
      }
    ) {
      nodes {
        ...EventData
      }
    }
  }
`;

export const GET_EVENT_BY_ID = gql`
  ${EVENT_DATA}
  query GetEventById($activityId: String!) {
    events(
      where: {
        metaQuery: {
          metaArray: {
            compare: EQUAL_TO
            key: "activity_id"
            type: NUMERIC
            value: $activityId
          }
        }
      }
    ) {
      nodes {
        ...EventData
      }
    }
  }
`;

//  {
//    compare: NOT_EQUAL_TO;
//    key: "cancelled";
//    type: NUMERIC;
//    value: "1";
//  }

export const GET_VENUE_PAGE = gql`
  query GetVenuePage($title: String!) {
    venuePages(where: { title: $title }) {
      nodes {
        seo {
          seoDescription
          seoKeywords
          seoDescriptionEn
          seoKeywordsEn
        }
        text_content {
          contentDe
          contentEn
        }
        titles {
          titleDe
          titleEn
        }
        gallery {
          vidPosition
          gallery {
            mediaItemUrl
            altText
            description
            mediaTexts {
              altTextEn
              copyright
              descriptionEn
            }
          }
          galleryMobile {
            mediaItemUrl
            altText
            description
            mediaTexts {
              altTextEn
              copyright
              descriptionEn
            }
          }
          logos {
            mediaItemUrl
            altText
            mediaTexts {
              altTextEn
              mediaLink
            }
          }
        }
        productionVideos {
          video1 {
            descriptionEn
            videoCopyright
            videoDescription
            videoUrl
            videoFile
          }
          video2 {
            descriptionEn
            videoCopyright
            videoDescription
            videoUrl
            videoFile
          }
          video3 {
            descriptionEn
            videoCopyright
            videoDescription
            videoUrl
            videoFile
          }
          video4 {
            descriptionEn
            videoCopyright
            videoDescription
            videoUrl
            videoFile
          }
          video5 {
            descriptionEn
            videoCopyright
            videoDescription
            videoUrl
            videoFile
          }
        }
      }
    }
  }
`;

export const GET_BANNER_EVENTS = gql`
  query GetEventsOfVenue($start: String!, $end: String!) {
    events(
      first: 100
      where: {
        metaQuery: {
          metaArray: [
            { compare: EQUAL_TO, key: "stage_id", value: "117" }
            {
              compare: GREATER_THAN_OR_EQUAL_TO
              key: "filterable_date"
              value: $start
            }
            {
              compare: LESS_THAN_OR_EQUAL_TO
              key: "filterable_date"
              value: $end
            }
          ]
          relation: AND
        }
      }
    ) {
      nodes {
        eventDetails {
          productionId
          filterableDate
        }
      }
    }
  }
`;

export const GET_EVENTS_OF_VENUE = gql`
  ${EVENT_DATA}
  query GetEventsOfVenue(
    $venueId: String!
    $start: String!
    $end: String!
    $first: Int!
    $after: String
    $search: String
  ) {
    events(
      first: $first
      after: $after
      where: {
        metaQuery: {
          metaArray: [
            { compare: BETWEEN, key: "stage_id", value: $venueId }
            {
              compare: GREATER_THAN_OR_EQUAL_TO
              key: "filterable_date"
              value: $start
            }
            {
              compare: LESS_THAN_OR_EQUAL_TO
              key: "filterable_date"
              value: $end
            }
            { compare: LIKE, key: "title", value: $search }
          ]
          relation: AND
        }
      }
    ) {
      nodes {
        ...EventData
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GET_EVENTS_BY_GENRE = gql`
  ${EVENT_DATA}
  query GetEventsByGenre(
    $tag: String!
    $start: String!
    $end: String!
    $first: Int!
    $after: String
    $omitVenue: String
  ) {
    events(
      first: $first
      after: $after
      where: {
        tag: $tag
        metaQuery: {
          metaArray: [
            { compare: NOT_EQUAL_TO, key: "stage_id", value: $omitVenue }
            {
              compare: GREATER_THAN_OR_EQUAL_TO
              key: "filterable_date"
              value: $start
            }
            {
              compare: LESS_THAN_OR_EQUAL_TO
              key: "filterable_date"
              value: $end
            }
          ]
        }
      }
    ) {
      nodes {
        ...EventData
      }
    }
  }
`;

// {
//   compare: NOT_EQUAL_TO;
//   key: "cancelled";
//   type: NUMERIC;
//   value: "1";
// }

export const SEARCH_EVENTS = gql`
  ${EVENT_DATA}
  query SearchEvents(
    $search: String!
    $start: String!
    $end: String!
    $first: Int!
    $after: String
    $omitVenue: String
  ) {
    events(
      first: $first
      after: $after
      where: {
        search: $search
        metaQuery: {
          metaArray: [
            { compare: NOT_EQUAL_TO, key: "stage_id", value: $omitVenue }
            {
              compare: GREATER_THAN_OR_EQUAL_TO
              key: "filterable_date"
              value: $start
            }
            {
              compare: LESS_THAN_OR_EQUAL_TO
              key: "filterable_date"
              value: $end
            }
            {
              compare: NOT_EQUAL_TO
              key: "cancelled"
              type: NUMERIC
              value: "1"
            }
          ]
        }
      }
    ) {
      nodes {
        ...EventData
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GET_SINGLE_EVENTS = gql`
  query GetSingleEvents {
    singleEvents {
      nodes {
        linkedEvents {
          linkedEventIds {
            ... on Event {
              id
            }
          }
        }
        slug
        showOnline {
          activateOn
          isOnline
        }
        eventDetails {
          activityId
        }
      }
    }
  }
`;

export const GET_SINGLE_EVENT = gql`
  ${EVENT_DATA}
  query GetSingleEvent($slug: ID!) {
    singleEvent(id: $slug, idType: SLUG) {
      id
      slug
      title
      linkedEvents {
        linkedEventIds {
          ... on Event {
            ...EventData
          }
        }
      }
      seo {
        seoDescription
        seoKeywords
        seoDescriptionEn
        seoKeywordsEn
      }
      eventDetails {
        activityId
        customInfo
        genre
        stageId
        title
        productionSubtitle
        productionTitleAddition
        subtitle
        originalTextDe
        originalTextEn
        eventDetailsDirector
        eventDetailsWriter
        eventTitle
        originalTitle
        eventType
        note
        noteLong
        productionId
        seriesPart
      }
      productionInfo {
        additionalInfoDe
        additionalInfoEn
        authorBullet
      }
      gallery {
        vidPosition
        gallery {
          mediaItemUrl
          altText
          description
          mediaTexts {
            altTextEn
            copyright
            descriptionEn
          }
        }
        galleryMobile {
          mediaItemUrl
          altText
          description
          mediaTexts {
            altTextEn
            copyright
            descriptionEn
          }
        }
        logos {
          mediaItemUrl
          altText
          mediaTexts {
            altTextEn
            mediaLink
          }
        }
      }
      productionVideos {
        video1 {
          descriptionEn
          videoCopyright
          videoDescription
          videoUrl
          videoFile
        }
        video2 {
          descriptionEn
          videoCopyright
          videoDescription
          videoUrl
          videoFile
        }
        video3 {
          descriptionEn
          videoCopyright
          videoDescription
          videoUrl
          videoFile
        }
        video4 {
          descriptionEn
          videoCopyright
          videoDescription
          videoUrl
          videoFile
        }
        video5 {
          descriptionEn
          videoCopyright
          videoDescription
          videoUrl
          videoFile
        }
      }
      text_content {
        contentDe
        contentEn
      }
      theasoftState {
        newType
      }
      subtitleInfo {
        moreTitleInfoDe
        moreTitleInfoEn
        subtitle
        showProdSubtitle
      }
      showOnline {
        activateOn
        isOnline
      }
    }
  }
`;

export const GET_SINGLE_EVENT_BY_SLUG = gql`
  query GetSingleEventBySlug($slug: ID!) {
    singleEvent(id: $slug, idType: SLUG) {
      showOnline {
        activateOn
        isOnline
      }
    }
  }
`;

export const GET_SHOW_BY_SLUG = gql`
  query GetShowBySlug($slug: ID!) {
    show(id: $slug, idType: SLUG) {
      productionDetails {
        isSeries
      }
      showOnline {
        activateOn
        isOnline
      }
    }
  }
`;

export const GET_SINGLE_EVENT_OF_ID = gql`
  query GetSingleEventOfId($id: String!, $activityId: String) {
    singleEvents(
      where: {
        metaQuery: {
          metaArray: [
            { compare: LIKE, key: "events_array", value: $id }
            { compare: EQUAL_TO, key: "activity_id", value: $activityId }
          ]
          relation: OR
        }
      }
    ) {
      nodes {
        slug
        showOnline {
          activateOn
          isOnline
        }
      }
    }
  }
`;

export const GET_SINGLE_EVENT_BY_ACTIVITY_ID = gql`
  query GetSingleEventByActivity($activityId: String!) {
    singleEvents(
      first: 100
      where: {
        metaQuery: {
          metaArray: {
            compare: EQUAL_TO
            key: "activity_id"
            type: NUMERIC
            value: $activityId
          }
        }
      }
    ) {
      nodes {
        slug
        showOnline {
          activateOn
          isOnline
        }
      }
    }
  }
`;

export const GET_PEOPLE = gql`
  query GetPeople($after: String) {
    persons(first: 300, after: $after) {
      nodes {
        id
        title
        slug
        personDetails {
          jobCategory
          jobDescription
          jobDescriptionEn
        }
        person_import {
          personId
          firstName
          lastName
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    pages(where: { title: "crew" }) {
      nodes {
        text_content {
          contentDe
          contentEn
        }
        titles {
          titleDe
          titleEn
        }
      }
    }
  }
`;

export const GET_PERSON = gql`
  query GetPerson($id: ID!) {
    person(id: $id, idType: SLUG) {
      personDetails {
        jobCategory
        jobDescription
        jobDescriptionEn
        noProductions
        linkedProductions {
          ... on Show {
            title
            slug
            status
            productionInfo {
              premiereEvent {
                ... on Event {
                  eventDetails {
                    startTime
                  }
                }
              }
              plannedPremiere {
                premiereDate
                premiereType
              }
            }
            productionDetails {
              isSeries
            }
            showOnline {
              activateOn
              isOnline
            }
          }
          ... on SingleEvent {
            title
            slug
            status
            showOnline {
              activateOn
              isOnline
            }
          }
        }
      }
      person_import {
        lastName
        firstName
        personId
      }
      slug
      title
      seo {
        seoDescription
        seoKeywords
        seoDescriptionEn
        seoKeywordsEn
      }
    }
  }
`;

export const GET_SHOWS_BY_PERSON = gql`
  query GetShowsByPerson($id: String!) {
    shows(first: 100, where: { status: PUBLISH, search: $id }) {
      nodes {
        title
        slug
        productionInfo {
          premiereEvent {
            ... on Event {
              eventDetails {
                startTime
              }
            }
          }
          plannedPremiere {
            premiereDate
            premiereType
          }
          showInRepertoire
          showInArchive
        }
        productionDetails {
          isSeries
        }
        showOnline {
          activateOn
          isOnline
        }
      }
    }
    singleEvents(where: { status: PUBLISH, search: $id }) {
      nodes {
        title
        slug
        showOnline {
          activateOn
          isOnline
        }
      }
    }
  }
`;

export const GET_PAGE_BY_TITLE = gql`
  query GetPageByTitle($title: String!) {
    pages(where: { title: $title }) {
      nodes {
        seo {
          seoDescription
          seoKeywords
          seoDescriptionEn
          seoKeywordsEn
        }
        text_content {
          contentDe
          contentEn
        }
        titles {
          titleDe
          titleEn
        }
        productionVideos {
          video1 {
            descriptionEn
            videoCopyright
            videoDescription
            videoUrl
            videoFile
          }
          video2 {
            descriptionEn
            videoCopyright
            videoDescription
            videoUrl
            videoFile
          }
          video3 {
            descriptionEn
            videoCopyright
            videoDescription
            videoUrl
            videoFile
          }
          video4 {
            descriptionEn
            videoCopyright
            videoDescription
            videoUrl
            videoFile
          }
          video5 {
            descriptionEn
            videoCopyright
            videoDescription
            videoUrl
            videoFile
          }
        }
        gallery {
          vidPosition
          gallery {
            mediaItemUrl
            altText
            description
            mediaTexts {
              altTextEn
              copyright
              descriptionEn
            }
          }
          galleryMobile {
            mediaItemUrl
            altText
            description
            mediaTexts {
              altTextEn
              copyright
              descriptionEn
            }
          }
          logos {
            mediaItemUrl
            altText
            mediaTexts {
              altTextEn
              mediaLink
            }
          }
        }
      }
    }
  }
`;

export const GET_HEADLINE = gql`
  query GetHeadline {
    headline @client
  }
`;

export const GET_ACTIVE_VENUES = gql`
  query GetActiveVenue {
    activeVenue @client
    selectedVenues @client
  }
`;

export const GET_ACTIVE_MENU = gql`
  query GetActiveMenu {
    activeMenu @client
  }
`;

export const GET_TIME_FRAME = gql`
  query GetTimeFrame {
    timeFrame @client
  }
`;

export const GET_LOADED_MONTHS = gql`
  query GetLoadedMonth {
    loadedMonths @client
  }
`;

export const GET_ANIMATION_ACTIVE = gql`
  query GetAnimationActive {
    animationActive @client
  }
`;

export const GET_HIDE_PAGE_1 = gql`
  query GetHidePage1 {
    hidePage1 @client
  }
`;

export const GET_ACTIVE_MEDIA_URL = gql`
  query GetActiveMediaUrl {
    activeMediaUrl @client
  }
`;
