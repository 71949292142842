import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { NavLink } from "react-router-dom";
import { useIntl } from "react-intl";
import { menuItems } from "../../i18n/route-utils";
import "./SubMenu.scss";
import { GET_ACTIVE_MENU, GET_HIDE_PAGE_1 } from "../../queries";

const SubMenu = () => {
  const { formatMessage, locale } = useIntl();
  const { data } = useQuery(GET_ACTIVE_MENU);
  const { data: page1Settings } = useQuery(GET_HIDE_PAGE_1);

  const items = useMemo(() => {
    if (menuItems[data.activeMenu]) {
      return page1Settings.hidePage1
        ? menuItems[data.activeMenu].filter((i) => i !== "page-1")
        : menuItems[data.activeMenu];
    }
  }, [data.activeMenu, page1Settings.hidePage1]);

  return (
    <nav className="subMenu" aria-label="Sub Navigation">
      <ul>
        {items &&
          items.map((item) => (
            <li>
              <NavLink
                key={item}
                to={`/${locale}/${formatMessage({ id: `route.${item}` })}`}
                activeClassName="active"
              >
                {formatMessage({ id: `menu.${item}` })}
              </NavLink>
            </li>
          ))}
      </ul>
    </nav>
  );
};

export default SubMenu;
