import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

import { menuItems } from "../../i18n/route-utils";
import "./MainMenu.scss";
import { activeMenu } from "../../cache";
import { Button } from "reakit";
import { useResizer } from "../../customHooks";
import SubMenu from "../subMenu/SubMenu";

const MainMenu = ({ closeMenu, hidden }) => {
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();
  const [savedPath, setSavedPath] = useState(pathname);
  const [activePoint, setActivePoint] = useState();
  const isMobile = useResizer();

  useEffect(() => {
    if (pathname !== savedPath && isMobile && closeMenu) {
      setSavedPath(pathname);
      closeMenu();
    }
  }, [pathname, isMobile, closeMenu]);

  useEffect(() => {
    for (const [key, value] of Object.entries(menuItems)) {
      for (const item of value) {
        if (pathname.includes(item)) {
          setActivePoint(key);
          activeMenu(key);
        }
      }
    }
  }, []);

  const onSelectItem = (item) => {
    if (activePoint !== item) {
      setActivePoint(item);
      activeMenu(item);
    } else {
      setActivePoint(null);
      activeMenu(null);
    }
  };

  return (
    <nav
      id="mainNav"
      aria-label={formatMessage({ id: "aria.main-nav" })}
      aria-hidden={hidden && isMobile}
    >
      <ul className="mainMenu">
        {menuItems &&
          Object.keys(menuItems).map((item) => (
            <li key={item}>
              {isMobile && (
                <div
                  className={`angle ${item === activePoint ? "active" : ""}`}
                >
                  &gt;
                </div>
              )}
              <Button
                key={item}
                className={`menuPoint ${item === activePoint ? "active" : ""}`}
                onClick={() => onSelectItem(item)}
                id={`menu-button-${item}`}
                aria-expanded={item === activePoint}
                aria-haspopup="menu"
              >
                {formatMessage({ id: `menu.${item}` })}
              </Button>
              {isMobile && item === activePoint && (
                <>
                  <div></div>
                  <SubMenu />
                </>
              )}
            </li>
          ))}
      </ul>
    </nav>
  );
};

MainMenu.propTypes = {
  closeMenu: PropTypes.func,
};

export default MainMenu;
