import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { Button } from "reakit/Button";
import { GET_ACTIVE_VENUES } from "../../queries";
import {
  venueStructure,
  venues,
  venueStructureMobile,
} from "../../i18n/route-utils";
import { activeVenue, selectedVenues } from "../../cache";
import "./LepoNav.scss";
import { useResizer } from "../../customHooks";

const LepoNav = () => {
  const { data } = useQuery(GET_ACTIVE_VENUES);
  // const isMobile = useResizer();
  // const allVenues = isMobile ? venueStructureMobile : venueStructure;
  const allVenues = venueStructure;

  useEffect(() => {
    if (data && data.activeVenue === "") {
      activeVenue(allVenues[0][0]);
    }
  }, [data, allVenues]);

  const changeVenue = (index, direction) => {
    let activeArray = [...data.selectedVenues];

    if (
      direction === "up" &&
      data.selectedVenues[index] < allVenues[index].length - 1
    ) {
      activeArray[index]++;
    }

    if (direction === "down" && data.selectedVenues[index] > 0) {
      activeArray[index]--;
    }

    activeVenue(allVenues[0][activeArray[index]]);
    selectedVenues(activeArray);
  };

  return (
    <div aria-hidden className="LepoNav">
      <div></div>
      {allVenues.map((vs, index) =>
        vs.length > 1 ? (
          <div
            className={`venueSwitch size-${vs.length} active-${data.selectedVenues[index]}`}
            key={index}
          >
            <Button
              className={`clickable arrow ${
                data.selectedVenues[index] < 1 ? "disabled" : ""
              }`}
              as="span"
              onClick={() => changeVenue(index, "down")}
            >
              &lt;
            </Button>
            <div className="buttons">
              {vs.map((v) => (
                <Button
                  as="h1"
                  className={`${venues[v]} ${
                    v === data.activeVenue ? "active" : ""
                  }`}
                  key={v}
                  onClick={() => activeVenue(v)}
                >
                  <FormattedMessage
                    id={`venue.nav.${venues[v]}`}
                    defaultMessage={venues[v]}
                  />
                </Button>
              ))}
            </div>
            <Button
              className={`clickable arrow ${
                data.selectedVenues[index] >= vs.length - 1 ? "disabled" : ""
              }`}
              as="span"
              onClick={() => changeVenue(index, "up")}
            >
              &gt;
            </Button>
          </div>
        ) : (
          <Button
            as="h2"
            className={`${venues[vs[0]]} ${
              vs[0] === data.activeVenue ? "active" : ""
            }`}
            key={vs[0]}
            onClick={() => activeVenue(vs[0])}
          >
            <FormattedMessage
              id={`venue.nav.${venues[vs[0]]}`}
              defaultMessage={venues[vs[0]]}
            />
          </Button>
        )
      )}
      {/* <Button
        as="h1"
        className={`other ${data.activeVenue === "other" ? "active" : ""}`}
        onClick={() => activeVenue("other")}
      >
        <FormattedMessage id="venue.other" defaultMessage="Grüner Salon" />
      </Button> */}
      <div></div>
    </div>
  );
};

export default LepoNav;
