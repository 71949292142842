import React from "react";
import Iframe from "react-iframe";
import "./Newsletter.scss";
import { useIntl } from "react-intl";

const Newsletter = () => {
  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [valid, setValid] = useState();
  // const regex = /^\S+@\S+\.\S+$/;

  // const validateForm = (evt) => {
  //   evt.stopPropagation();
  //   evt.preventDefault();
  //   console.log(regex.test(email));
  //   setValid(regex.test(email));
  // };

  const { locale } = useIntl();

  return (
    <Iframe
      url={
        locale === "de"
          ? "/newsletter/newsletter.html"
          : "/newsletter/newsletter-en.html"
      }
      width="100%"
      height="450px"
      id="newsletterIFrame"
      className="myClassname"
      display="initial"
      position="relative"
    />
  );

  // return (
  //   <div className="Newsletter">
  //     <form>
  //       <label htmlFor="name">
  //         <span>
  //           <FormattedMessage id="input.name" defaultMessage="Name" />
  //         </span>
  //         <input
  //           type="text"
  //           id="name"
  //           name="name"
  //           value={name}
  //           onChange={(e) => setName(e.target.value)}
  //         />
  //       </label>
  //       <label htmlFor="email">
  //         <span>
  //           <FormattedMessage id="input.email" defaultMessage="Email" />*
  //         </span>
  //         <input
  //           type="text"
  //           id="email"
  //           name="email"
  //           value={email}
  //           onChange={(e) => setEmail(e.target.value)}
  //         />
  //       </label>
  //       <Button type="submit" onClick={validateForm}>
  //         &gt;&nbsp;
  //         <FormattedMessage id="input.submit" defaultMessage="Email" />
  //       </Button>
  //     </form>
  //   </div>
  // );
};

export default Newsletter;
