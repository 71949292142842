import React, { useState, useEffect, useCallback } from "react";
import Leporello from "../components/leporello/Leporello";
import moment from "moment";
// import axios from "axios";
// import { useRouteMatch, useParams, Redirect, Route } from "react-router-dom";
import { useIntl } from "react-intl";
import { zeroPad } from "../utilities";
import {
  GET_INITIAL_SETTINGS,
  GET_LOADED_MONTHS,
  GET_SPIELZEITEN,
} from "../queries";
import { useQuery } from "@apollo/client";
import SpecialEvents from "../components/specialEvents/SpecialEvents";
import { loadedMonths } from "../cache";
import { HashLink } from "react-router-hash-link";

const Programme = ({ scrollElem }) => {
  const [dataReady, setDataReady] = useState(true);
  const [showMonths, setShowMonths] = useState();
  const [numMonths, setNumMonths] = useState(1);
  const [isFragment] = useState(!!(moment().date() > 1));
  const { formatMessage, formatDate } = useIntl();

  const { data: savedMonths } = useQuery(GET_LOADED_MONTHS, {
    onCompleted: (data) => {
      if (data.length) {
        setShowMonths(data);
      }
    },
  });

  useEffect(() => {
    if (dataReady && showMonths && showMonths.length !== numMonths) {
      setNumMonths(showMonths.length);
    }
  }, [dataReady, showMonths, numMonths]);

  const { data } = useQuery(GET_INITIAL_SETTINGS, {
    onCompleted: (data) => {
      const settings = data.vbSetting.vb_custom_settings;
      const end =
        process.env.REACT_APP_ENV === "DEV"
          ? settings.endmonthDev
          : settings.endmonth;

      let now = moment();
      let y;
      let m;

      if (now.isBefore(settings.startmonth)) {
        y = moment(settings.startmonth).year();
        m = moment(settings.startmonth).month() + 1;
      } else {
        y = now.year();
        m = now.month() + 1;
      }

      let nextMonth = now
        .clone()
        .add(1, "month")
        .startOf("month")
        //needed for summer time
        .add(3, "hour");

      let oneMoreMonth = nextMonth
        .clone()
        .add(1, "month")
        .startOf("month")
        .add(3, "hour");

      let monthArray;

      if (
        nextMonth.isSameOrBefore(moment(end).startOf("month").add(3, "hour"))
      ) {
        monthArray = [
          { y, m, start: "even", end: null },
          {
            y: nextMonth.year(),
            m: nextMonth.month() + 1,
            start: "even",
            end: null,
          },
        ];

        if (
          oneMoreMonth.isSameOrBefore(
            moment(end).startOf("month").add(3, "hour")
          )
        ) {
          monthArray = [
            ...monthArray,
            {
              y: oneMoreMonth.year(),
              m: oneMoreMonth.month() + 1,
              start: "even",
              end: null,
            },
          ];
        }

        setShowMonths(monthArray);
      } else {
        setShowMonths([{ y, m, start: "even", end: null }]);
      }
    },
    skip: savedMonths && savedMonths.length,
  });

  const { data: seasons } = useQuery(GET_SPIELZEITEN);

  const getIsInSeason = (date) => {
    if (seasons && seasons.spielzeiten && seasons.spielzeiten.nodes) {
      const dayDate = moment(date);

      for (const season of seasons.spielzeiten.nodes) {
        const seasonEnd = moment(season.spielzeitInfo.spielzeitEnd);
        const seasonStart = moment(season.spielzeitInfo.spielzeitStart);

        if (
          dayDate.isSameOrAfter(seasonStart) &&
          dayDate.isSameOrBefore(seasonEnd)
        ) {
          return true;
        }
      }

      return false;
    }
  };

  const scrollOnePx = useCallback(
    (dir = "top") => {
      if (dir === "top") {
        // const { scrollHeight, offsetHeight, scrollTop } = scrollElem.current;
        scrollElem.current.scrollTo({
          top: 1,
          left: 0,
          // behavior: "smooth",
        });
      }

      if (dir === "bottom") {
        const { scrollHeight, offsetHeight } = scrollElem.current;
        scrollElem.current.scrollTo({
          top: scrollHeight + offsetHeight - 1,
          left: 0,
          // behavior: "smooth",
        });
      }
    },
    [scrollElem]
  );

  useEffect(() => {
    if (showMonths && showMonths.length && showMonths !== loadedMonths) {
      loadedMonths(showMonths);
    }
  }, [showMonths]);

  // useEffect(() => {
  //   if (year && month) {
  //     client.query({
  //       query: GET_EVENTS_BETWEEN,
  //       variables: {
  //         start: `${year}${month}00`,
  //         end: `${year}${month}31`,
  //       },
  //     });
  //   }
  // }, [year, month]);

  const getMonthId = (month, year) => {
    return formatDate(moment().set({ month: Number(month) - 1, year: year }), {
      month: "long",
      year: "numeric",
    });
  };

  return (
    <main
      role="main"
      className="Programme"
      aria-label={formatMessage({ id: "aria.programme" })}
    >
      {showMonths && (
        <nav>
          {showMonths.map((month) => (
            <HashLink
              key={getMonthId(month.m, month.y)}
              className="screenreader"
              to={`#${getMonthId(month.m, month.y)}`.replace(/\s/g, "-")}
            >
              {getMonthId(month.m, month.y)}
            </HashLink>
          ))}
        </nav>
      )}
      {data &&
        // year &&
        // month &&
        showMonths &&
        showMonths.map((item) => (
          <Leporello
            key={`${item.m}${item.y}`}
            month={zeroPad(Number(item.m), 2)}
            year={item.y}
            monthId={getMonthId(item.m, item.y).replace(/\s/g, "-")}
            even={item.even}
            setDataReady={setDataReady}
            scrollableDiv={scrollElem}
            startEven={true}
            // setEvenOdd={setMonthEvenOdd}
            fragment={isFragment}
            scrollOnePx={scrollOnePx}
            settings={data}
            getIsInSeason={getIsInSeason}
          />
        ))}
      <SpecialEvents />
    </main>
  );
};

// export default Programme;

export default React.memo(Programme);
