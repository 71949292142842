import React from "react";
import { FormattedMessage } from "react-intl";
import "./ProductionTitle.scss";
import parse from "html-react-parser";
import {
  getVenueClass,
  isGrossesHaus,
  venues,
} from "../../../i18n/route-utils";

const ProductionTitle = ({ title, subtitle, authors, venueId }) => {
  return (
    <div
      className={`productionTitle ${
        venueId ? getVenueClass(venueId) : "default"
      }`}
    >
      <h1>{title}</h1>
      <div className="titleInfos">
        {subtitle && (
          <span>
            <span aria-hidden className="divider">
              &bull;&nbsp;
            </span>
            <h2>{parse(subtitle)} </h2>
          </span>
        )}
        {authors && (
          <span>
            <span aria-hidden className="divider">
              &bull;&nbsp;
            </span>
            <FormattedMessage id="show.by" defaultMessage="von" />
            &nbsp;{parse(authors)}
          </span>
        )}
        {venueId && !isGrossesHaus.includes(Number(venueId)) && (
          <span className="nobreak">
            <span aria-hidden className="divider">
              &bull;&nbsp;
            </span>
            <FormattedMessage
              id={`venue.${venues[venueId]}`}
              defaultMessage={venues[venueId]}
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default ProductionTitle;
