import { useCallback } from "react";
import { useIntl } from "react-intl";
import parse from "html-react-parser";

const IntText = ({ de, en, withFallback, showOtherLang }) => {
  const { locale } = useIntl();

  const getText = useCallback(() => {
    const noContent = !!(!de && !en);

    if (locale === "de") {
      if (showOtherLang && !de) {
        return en;
      }

      return (
        de ||
        (withFallback && !noContent ? "Deutsche Version folgt in Kürze" : "")
      );
    }
    if (locale === "en") {
      if (showOtherLang && !en) {
        return de;
      }

      return (
        en || (withFallback && !noContent ? "English Version coming soon" : "")
      );
    }

    return "";
  }, [locale, de, en, withFallback]);

  return <>{parse(getText())}</>;
};

export default IntText;
