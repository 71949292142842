import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

const SearchResult = ({ data, type, onClose }) => {
  const { locale, formatMessage } = useIntl();
  const intl = useIntl();

  const getLink = () => {
    let link = `${locale}`;

    const isGruenerSalon = !!(
      data.productionInfo && data.productionInfo.venue === "147"
    );

    const isP14 = !!data.title.startsWith("P14");

    switch (type) {
      case "shows":
        link = isP14
          ? `/${link}/p14/${data.slug}`
          : `/${link}/${
              isGruenerSalon ? "gruener-salon/" : ""
            }${intl.formatMessage({
              id: `${
                data.productionDetails.isSeries
                  ? "route.series"
                  : "route.repertoire"
              }`,
            })}/${data.slug}`;
        break;
      case "persons":
        link = `/${link}/${intl.formatMessage({ id: `route.crew` })}/${
          data.slug
        }`;
        break;
      case "singleEvents":
        link = `/${link}/${intl.formatMessage({ id: `route.events` })}/${
          data.slug
        }`;
        break;
      default:
        link = `/${link}/${locale === "de" ? data.slug : data.titles.slugEn}`;
        break;
    }

    return link;
  };

  return data ? (
    <div className="searchResultWrapper">
      <span aria-hidden>&bull;&nbsp;</span>
      <Link
        to={getLink}
        id={data.id}
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
        className="searchResult underline"
      >
        {type === "pages"
          ? formatMessage({ id: `menu.${data.titles.slugEn}` })
          : data.title}
      </Link>
    </div>
  ) : null;
};

export default SearchResult;
