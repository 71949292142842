import { useParams } from "react-router-dom";
import Leporello from "./Leporello";

const LepoWrapper = ({ scrollElem }) => {
  const { paramYear, paramMonth } = useParams();

  return (
    <Leporello
      month={paramMonth}
      year={paramYear}
      scrollableDiv={scrollElem}
      getIsInSeason={(day) => true}
      loadViaParams={true}
    />
  );
};

export default LepoWrapper;
