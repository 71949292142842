import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import LepoItem from "./LepoItem";
import DateField from "../../atoms/dateField/DateField";
import { v4 as uuid } from "uuid";
import "./LepoRow.scss";
import {
  getVenueClass,
  ignoreSpecial,
  venues,
  venueStructure,
  venueStructureMobile,
} from "../../../i18n/route-utils";
import { useResizer } from "../../../customHooks";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { isFestiwalla } from "../../../utilities";

const LepoRow = ({
  date,
  dateString,
  data,
  selectedVenues,
  scrollableDiv,
  startAt,
}) => {
  const isMobile = useResizer();
  const { locale } = useIntl();
  const allVenues = isMobile ? venueStructureMobile : venueStructure;
  // const isToday = moment().isSame(date, "day");
  // const [scrolled, setScrolled] = useState(false);

  // const onRefChange = useCallback(
  //   (node) => {
  //     if (node === null) {
  //       // DOM node referenced by ref has been unmounted
  //     } else {
  //       if (startAt && !scrolled && date) {
  //         if (
  //           date.date() === startAt.date() &&
  //           date.month() === startAt.month()
  //         ) {
  //           setTimeout(() => {
  //             const { offsetTop } = node;
  //             node.scrollIntoView(true);
  //             // const { offsetTop } = ref.current;
  //             // console.log(offsetTop, headerHeight);
  //             scrollableDiv.current.scrollTo({
  //               //TODO: scroll to bottom of header
  //               top: offsetTop,
  //               // behavior: "smooth",
  //             });

  //             setScrolled(true);
  //           }, 100);
  //         }
  //       }
  //       // DOM node referenced by ref has changed and exists
  //     }
  //   },
  //   [startAt, date, scrolled, scrollableDiv]
  // );
  const getSpecialEvents = () => {
    if (data && data.venues) {
      const specialAtVenues = [];
      for (let v of data.venues) {
        for (let event of v.events) {
          if (
            event &&
            event.eventDetails &&
            event.eventDetails.customInfo &&
            !ignoreSpecial.includes(event.eventDetails.customInfo)
          ) {
            specialAtVenues.push(String(event.eventDetails.stageId));
          }
        }
      }
      return specialAtVenues;
    }
  };

  const specialEvents = getSpecialEvents();

  return data ? (
    <div
      className={`LepoRow ${data.even ? "even" : "odd"} ${
        specialEvents.length ? "special" : ""
      } ${isMobile ? "mobile" : ""} ${
        isFestiwalla(dateString) ? "festival" : ""
      }`}
      role="rowgroup"
      // ref={onRefChange}
    >
      <div
        className={`dates start ${venues[allVenues[0][selectedVenues[0]]]}`}
        aria-hidden
      >
        <DateField date={date} />
        {[...Array(data.rows - 1)].map((value, i) => (
          <div key={`empty-start-${date}${i}`} className="emptyDate">
            <div className="weekday">
              {moment(date).locale(locale) === "en"
                ? moment(date).locale(locale).format("ddd")
                : moment(date).locale(locale).format("dd")}
            </div>
          </div>
        ))}
      </div>

      <div className="events">
        {data.rows &&
          [...Array(data.rows)].map((value, i) => (
            <div className="row" role="row" key={i}>
              {data &&
                data.venues &&
                allVenues.map((vs, j) => (
                  <div
                    key={`venue${j}`}
                    className={`venueSwitch active-${selectedVenues[j]}`}
                  >
                    <div
                      className={`venueContainer size-${allVenues[j].length}`}
                    >
                      {vs.map((v, index) => (
                        <div
                          className={`itemContainer ${getVenueClass(v)}`}
                          key={`${v}${i}${j}`}
                        >
                          {data.venues.find(
                            (x) => Number(x.id) === Number(v)
                          ) !== undefined &&
                          data.venues.find((x) => Number(x.id) === Number(v))
                            .events[i] ? (
                            <LepoItem
                              key={`${
                                data.venues.find(
                                  (x) => Number(x.id) === Number(v)
                                ).events[i].eventDetails.activityId
                              }-${
                                data.venues.find(
                                  (x) => Number(x.id) === Number(v)
                                ).events[i].eventDetails.startTime
                              }`}
                              data={
                                data.venues.find(
                                  (x) => Number(x.id) === Number(v)
                                ).events[i]
                              }
                              mainVenueId={v}
                            />
                          ) : (
                            <div className="emptyCell" role="cell"></div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          ))}
      </div>
      <div
        className={`dates end ${
          venues[
            allVenues[allVenues.length - 1][
              selectedVenues[selectedVenues.length - 1]
            ]
          ]
        }`}
        aria-hidden
      >
        <DateField date={date} />
        {[...Array(data.rows - 1)].map((value, i) => (
          <div className="emptyDate" key={`empty-end-${date}${i}`}></div>
        ))}
      </div>
    </div>
  ) : null;
};

LepoRow.propTypes = {
  data: PropTypes.object,
  selectedVenues: PropTypes.array,
};

export default LepoRow;
