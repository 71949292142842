import React from "react";
import moment from "moment";
import "moment/locale/de";
import { useIntl } from "react-intl";
import "./DateField.scss";

const DateField = ({ date, archive }) => {
  const { locale } = useIntl();
  let localDate = moment(date).locale(locale);

  return (
    <time className="date" dateTime={moment(date).format("DD.MM.YYYY")}>
      {archive ? (
        <div className="weekday">{localDate.format("YYYY")}</div>
      ) : (
        <div className="weekday">
          {locale === "en" ? localDate.format("ddd") : localDate.format("dd")}
          {/* <FormattedDate value={date} weekday="short" timeZone="UTC" /> */}
        </div>
      )}
      <div className="day">
        {moment(date).format("DD")}
        {/* <FormattedDate value={date} day="numeric" /> */}
      </div>
      <div className="month">
        {locale === "de"
          ? localDate.format("MMM").replace(".", "").substring(0, 3)
          : localDate.format("MMM")}
        {/* <FormattedDate value={date} month="short" timeZone="UTC" /> */}
      </div>
    </time>
  );
};

export default DateField;
