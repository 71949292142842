import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import German from "../localizations/de.json";
import English from "../localizations/en.json";

export const IntlContext = React.createContext();
export const availableLanguages = ["de", "en"];

export const local = navigator.language;

let localeMessages = {
  de: German,
  en: English,
};

const IntlWrapper = (props) => {
  const [locale, setLocale] = useState(local.substring(0, 2));
  const [intlSlugs, setIntlSlugs] = useState({});

  return (
    <IntlContext.Provider
      value={{ locale, setLocale, intlSlugs, setIntlSlugs }}
    >
      <IntlProvider messages={localeMessages[locale]} locale={locale}>
        {props.children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};

export default IntlWrapper;
