import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useResizer } from "../../customHooks";
import "./CookieBanner.scss";

const CookieBanner = () => {
  const { formatMessage, locale } = useIntl();
  const isMobile = useResizer();

  const removeCookie = () => {
    Cookies.remove("CookieConsent");
  };

  const setMatomoData = () => {
    var _paq = (window._paq = window._paq || []);

    _paq.push(["rememberConsentGiven"]);
    _paq.push(["rememberCookieConsentGiven"]);
  };

  return (
    <CookieConsent
      enableDeclineButton
      // debug={true}
      disableStyles={true}
      setDeclineCookie={false}
      onDecline={removeCookie}
      onAccept={setMatomoData}
      buttonText={formatMessage({
        id: "cookieBanner.accept",
        defaultMessage: "accept Matomo",
      })}
      declineButtonText={formatMessage({
        id: "cookieBanner.decline",
        defaultMessage: "decline Matomo",
      })}
      ariaAcceptLabel={formatMessage({
        id: "cookieBanner.accept",
        defaultMessage: "accept Matomo",
      })}
      ariaDeclineLabel={formatMessage({
        id: "cookieBanner.decline",
        defaultMessage: "decline Matomo",
      })}
      buttonClasses="cookieBtn"
    >
      <FormattedMessage
        id={isMobile ? "cookieBanner.info.mobile" : "cookieBanner.info"}
        defaultMessage="Diese Seite benutzt Cookies"
      />
      &nbsp;
      <Link to={`/${locale}/${formatMessage({ id: "route.privacy-policy" })}`}>
        <FormattedMessage
          id="cookieBanner.link"
          defaultMessage="Datenschutzerklärung"
        />
      </Link>
    </CookieConsent>
  );
};

export default CookieBanner;
