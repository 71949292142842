import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, Redirect, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { filterActiveShows, showActive } from "../../utilities";
import Gallery from "../gallery/Gallery";
import { getVenueClass, venues } from "../../i18n/route-utils";
import parse from "html-react-parser";
import EventCalendar from "../eventCalendar/EventCalendar";
import { headline } from "../../cache";
import "../show/Show.scss";
import {
  GET_SHOW_BY_ID,
  GET_SINGLE_EVENT,
  GET_EVENT_BY_ID,
} from "../../queries";
import Logos from "../logos/Logos";
import MediaCredits from "../atoms/mediaCredits/MediaCredits";
import Loader from "../atoms/loader/Loader";
import SeoTags from "../atoms/seoTags/SeoTags";
import IntText from "../atoms/intTexts/IntTexts";
import { HashLink } from "react-router-hash-link";

const SingleEvent = () => {
  const { locale, formatMessage } = useIntl();
  const { slug } = useParams();
  const description = useRef();
  const titleRef = useRef();
  const [firstEvent, setFirstEvent] = useState();
  const [linkedEvents, setLinkedEvents] = useState(null);
  const [activityId, setActivityId] = useState();
  const [renderedTitle, setRenderedTitle] = useState("");
  const [isActive, setIsActive] = useState();
  const [seriesId, setSeriesId] = useState();
  const [linkBack, setLinkBack] = useState("");

  const { data, loading, error } = useQuery(GET_SINGLE_EVENT, {
    variables: { slug },
    onCompleted: (data) => {
      let SE = data.singleEvent;

      // set title
      if (SE) {
        let title = SE.eventDetails.originalTitle
          ? SE.eventDetails.originalTitle
          : SE.eventDetails.eventTitle;

        setRenderedTitle(title);

        if (
          SE.linkedEvents.linkedEventIds &&
          SE.linkedEvents.linkedEventIds[0]
        ) {
          setLinkedEvents(SE.linkedEvents.linkedEventIds);
          setFirstEvent(SE.linkedEvents.linkedEventIds[0]);
        } else {
          setLinkedEvents([]);
          setFirstEvent(null);
          if (SE && SE.eventDetails && SE.eventDetails.activityId) {
            setActivityId(String(SE.eventDetails.activityId));
          }
        }

        setIsActive(showActive(SE));
        if (SE.eventDetails.seriesPart) {
          setSeriesId(String(SE.eventDetails.productionId));
        }
      }
    },
  });

  useQuery(GET_EVENT_BY_ID, {
    skip: !activityId,
    variables: {
      activityId,
    },
    onCompleted: (data) => {
      setLinkedEvents(data.events.nodes);
      setFirstEvent(data.events.nodes[0]);
    },
  });

  useQuery(GET_SHOW_BY_ID, {
    variables: { id: seriesId },
    skip: !seriesId,
    onCompleted: (data) => {
      let activeShows = filterActiveShows(data.shows.nodes);
      if (activeShows && activeShows[0]) {
        let url = `/${locale}/${
          activeShows[0].productionInfo.venue === "147" ? "gruener-salon/" : ""
        }${formatMessage({
          id: `route.series`,
        })}/${activeShows[0].slug}`;
        let linkTitle = activeShows[0].title;

        setLinkBack({ url, linkTitle });
      } else {
        setLinkBack("");
      }
    },
  });

  useEffect(() => {
    headline("");
  }, []);

  if (error || isActive === false) {
    return <Redirect to={`/${locale}/404`} />;
  }

  if (loading) {
    return <Loader />;
  }

  if (data && data.singleEvent) {
    return (
      <>
        <SeoTags
          title={`${
            data.singleEvent.eventDetails.title &&
            data.singleEvent.eventDetails.title !== "*" &&
            !data.singleEvent.eventDetails.title.startsWith("#")
              ? parse(data.singleEvent.eventDetails.title)
              : ""
          }
            ${parse(renderedTitle || "")}
            ${
              !renderedTitle &&
              !data.singleEvent.eventDetails.title &&
              data.singleEvent.title
                ? parse(data.singleEvent.title)
                : ""
            }`}
          seo={data.singleEvent.seo}
        />
        <nav className="screenreader">
          <HashLink to="#events">
            <FormattedMessage id="anchor.events" defaultMessage="Events" />
          </HashLink>
        </nav>
        <main
          className={`singleEvent show ${
            linkedEvents && linkedEvents.length > 0
              ? getVenueClass(linkedEvents[0].eventDetails.stageId)
              : ""
          }`}
        >
          {/* <main className={`singleEvent show`}> */}
          <div className="topImage">
            <Gallery
              data={data.singleEvent.gallery}
              videos={data.singleEvent.productionVideos}
            />
          </div>
          <div className="titleSection" ref={titleRef}>
            <h1>
              {data.singleEvent.eventDetails.title &&
                !(
                  data.singleEvent.theasoftState &&
                  data.singleEvent.theasoftState.newType
                ) &&
                !(
                  renderedTitle &&
                  renderedTitle.startsWith(data.singleEvent.eventDetails.title)
                ) &&
                data.singleEvent.eventDetails.title !== "*" &&
                !data.singleEvent.eventDetails.title.startsWith("#") && (
                  <>
                    <span>{parse(data.singleEvent.eventDetails.title)}</span>
                    {renderedTitle && (
                      <span role="separator" className="divider">
                        <span aria-hidden>&nbsp;&bull;&nbsp;</span>
                      </span>
                    )}
                  </>
                )}
              {renderedTitle && <span>{parse(renderedTitle)}</span>}
              {!renderedTitle &&
                !data.singleEvent.eventDetails.title &&
                data.singleEvent.title && (
                  <span>&nbsp;{parse(data.singleEvent.title)}</span>
                )}
              {data.singleEvent.eventDetails.productionTitleAddition && (
                <span>
                  <span role="separator" className="divider">
                    <span aria-hidden>&nbsp;&bull;&nbsp;</span>
                  </span>
                  <span>
                    {parse(
                      data.singleEvent.eventDetails.productionTitleAddition
                    )}
                  </span>
                </span>
              )}
            </h1>
            <div className="titleInfos">
              {data.singleEvent.eventDetails.productionSubtitle &&
                data.singleEvent.subtitleInfo.showProdSubtitle && (
                  <span>
                    <span role="separator" className="divider">
                      <span aria-hidden>&nbsp;&bull;&nbsp;</span>
                    </span>
                    <span>
                      {parse(data.singleEvent.eventDetails.productionSubtitle)}
                    </span>
                  </span>
                )}
              {data.singleEvent.eventDetails &&
                data.singleEvent.eventDetails.subtitle && (
                  <span>
                    <span role="separator" className="divider">
                      <span aria-hidden>&nbsp;&bull;&nbsp;</span>
                    </span>
                    <span>
                      {parse(data.singleEvent.eventDetails.subtitle)}{" "}
                    </span>
                  </span>
                )}
              {data.singleEvent.eventDetails &&
                !data.singleEvent.theasoftState.newType &&
                data.singleEvent.eventDetails.noteLong && (
                  <span>
                    <span role="separator" className="divider">
                      <span aria-hidden>&nbsp;&bull;&nbsp;</span>
                    </span>
                    <span>
                      {parse(
                        data.singleEvent.eventDetails.noteLong.replace(
                          /^(-\s)/gm,
                          ""
                        )
                      )}{" "}
                    </span>
                  </span>
                )}
              {data.singleEvent.subtitleInfo &&
                data.singleEvent.subtitleInfo.subtitle &&
                data.singleEvent.subtitleInfo.subtitle !==
                  data.singleEvent.eventDetails.subtitle && (
                  <span>
                    <span role="separator" className="divider">
                      <span aria-hidden>&nbsp;&bull;&nbsp;</span>
                    </span>
                    <span>
                      {parse(data.singleEvent.subtitleInfo.subtitle)}{" "}
                    </span>
                  </span>
                )}
              {data.singleEvent.subtitleInfo &&
                (data.singleEvent.subtitleInfo.moreTitleInfoDe ||
                  data.singleEvent.subtitleInfo.moreTitleInfoEn) && (
                  <span>
                    <span role="separator" className="divider">
                      <span aria-hidden>&nbsp;&bull;&nbsp;</span>
                    </span>
                    <span>
                      <IntText
                        de={data.singleEvent.subtitleInfo.moreTitleInfoDe}
                        en={data.singleEvent.subtitleInfo.moreTitleInfoEn}
                        showOtherLang={true}
                      />{" "}
                    </span>
                  </span>
                )}
              {data.singleEvent.eventDetails &&
                data.singleEvent.eventDetails.note &&
                data.singleEvent.subtitleInfo.subtitle !==
                  data.singleEvent.eventDetails.note && (
                  <span>
                    <span role="separator" className="divider">
                      <span aria-hidden>&nbsp;&bull;&nbsp;</span>
                    </span>
                    <span>{parse(data.singleEvent.eventDetails.note)} </span>
                  </span>
                )}
              {data.singleEvent.eventDetails &&
                data.singleEvent.eventDetails.eventDetailsWriter && (
                  <span>
                    {data.singleEvent.productionInfo.authorBullet && (
                      <span role="separator" className="divider">
                        <span aria-hidden>&nbsp;&bull;&nbsp;</span>
                      </span>
                    )}
                    <span>
                      {parse(data.singleEvent.eventDetails.eventDetailsWriter)}{" "}
                    </span>
                  </span>
                )}
              {(data.singleEvent.eventDetails.originalTextDe ||
                data.singleEvent.eventDetails.originalTextEn) && (
                <>
                  {(data.singleEvent.productionInfo.authorBullet ||
                    data.singleEvent.eventDetails.eventDetailsWriter) && (
                    <span role="separator" className="divider">
                      <span aria-hidden>&nbsp;&bull;&nbsp;</span>
                    </span>
                  )}
                  <span>
                    <IntText
                      de={data.singleEvent.eventDetails.originalTextDe}
                      en={data.singleEvent.eventDetails.originalTextEn}
                      showOtherLang={true}
                    />{" "}
                  </span>
                </>
              )}
              {data.singleEvent.eventDetails &&
                data.singleEvent.eventDetails.eventType && (
                  <span>
                    <span role="separator" className="divider">
                      <span aria-hidden>&nbsp;&bull;&nbsp;</span>
                    </span>
                    <span>
                      {parse(data.singleEvent.eventDetails.eventType)}{" "}
                    </span>
                  </span>
                )}
              {data.singleEvent.eventDetails &&
                data.singleEvent.eventDetails.stageId &&
                String(data.singleEvent.eventDetails.stageId) !==
                  String(117) && (
                  <span className="nobreak">
                    <span role="separator" className="divider">
                      <span aria-hidden>&nbsp;&bull;&nbsp;</span>
                    </span>
                    <FormattedMessage
                      id={`venue.${
                        venues[data.singleEvent.eventDetails.stageId]
                      }`}
                      defaultMessage={
                        venues[data.singleEvent.eventDetails.stageId]
                      }
                    />
                  </span>
                )}
            </div>
          </div>
          <section className="mainContent">
            {data && (
              <div className={`descriptionWrapper`}>
                <div ref={description} className="description">
                  {data.singleEvent.text_content && (
                    <div>
                      <IntText
                        de={data.singleEvent.text_content.contentDe}
                        en={data.singleEvent.text_content.contentEn}
                        withFallback
                      />
                    </div>
                  )}
                  {data.singleEvent.productionInfo &&
                    data.singleEvent.productionInfo.additionalInfoDe && (
                      <p className="additionalInfo">
                        <IntText
                          de={data.singleEvent.productionInfo.additionalInfoDe}
                          en={data.singleEvent.productionInfo.additionalInfoEn}
                        />
                      </p>
                    )}
                  <Logos logos={data.singleEvent.gallery.logos} />
                  <MediaCredits
                    galleryImages={data.singleEvent.gallery.gallery}
                    vidPosition={data.singleEvent.gallery.vidPosition}
                    videoData={data.singleEvent.productionVideos}
                  />
                </div>

                {/* {isMobile && (
                <Button
                  as="div"
                  className="showMore"
                  onClick={() => setShowDescription(!showDescription)}
                >
                  <FormattedMessage
                    id={showDescription ? "show.showLess" : "show.showMore"}
                    defaultMessage={showDescription ? "[less]" : "[...more]"}
                  />
                </Button>
              )} */}
              </div>
            )}
            <div
              className="calendarWrapper"
              id="events"
              // style={{ top: `${titleHeight + 25}px` }}
            >
              {linkedEvents && linkedEvents.length > 0 && (
                <>
                  <EventCalendar
                    key={`evt-normal-${data.singleEvent.eventDetails.title}-${renderedTitle}`}
                    venueId={Number(linkedEvents[0].eventDetails.stageId)}
                    type="noQuery"
                    data={linkedEvents}
                  />
                  {firstEvent && (
                    <>
                      <EventCalendar
                        key={`evt-archive-${data.singleEvent.eventDetails.title}-${renderedTitle}`}
                        venueId={Number(linkedEvents[0].eventDetails.stageId)}
                        type="noQuery"
                        data={linkedEvents}
                        withArchive
                        withSeparator
                      />
                    </>
                  )}
                </>
              )}
              {linkBack && linkBack.url && linkBack.linkTitle && (
                <Link to={linkBack.url} className="linkBack">
                  &gt;&nbsp;
                  <FormattedMessage
                    id="link.back"
                    defaultMessage="zur Übersicht"
                  />
                  &nbsp;{linkBack.linkTitle}
                </Link>
              )}
            </div>
          </section>
        </main>
      </>
    );
  } else {
    return null;
  }
};

export default SingleEvent;
